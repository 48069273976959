export function addToMetamask(tokenAddress, tokenSymbol, tokenDecimals, tokenImage, chainId) {
  if (ethereum.networkVersion !== chainId) {
    Toaster.toast(I18n.t("metamask.unsupported"));
    return;
  }

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      Toaster.toast(I18n.t("metamask.successful"));
    }
  } catch (error) {
    Toaster.toast(I18n.t("metamask.unknown_error"));
    console.log(error);
  }
}
