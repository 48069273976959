import { SELECT_SEARCH_EVENT, SELECT_SEARCH_HIGHLIGHTS } from "../../mixpanel_config";
import { searchCategoryEventProperties } from "../../analytics";

/**
 * @param {*} chains
 * @param {string|URL} url
 */
export const buildResultItem = ({
  title,
  subtitle,
  image,
  icon,
  suffix,
  url,
  data,
  type = "Unknown",
  trending = false,

  // Tracking-related
  interestDataKey,
  interestDataValue,
  analyticsPropertyId
}) => {
  let thumbnail = `<div class="tw-w-6 tw-mr-2"></div>`;
  if (image) {
    thumbnail = `<img src="${image}" class="tw-w-6 tw-mr-2" loading="lazy"/>`;
  } else if (icon) {
    thumbnail = `<i class="fas ${icon} dark:tw-opacity-60 tw-w-6 tw-mr-2"></i>`;
  }

  let div = document.createElement("div");
  div.classList.add("results-item");
  div.setAttribute("data-action", "click->analytics-tracker#trackEvent click->targeting-ads#addSearchItemToInterest click->search-v2#storeRecentlySearched mouseenter->search-v2#renderSmartResult mouseenter->search-v2#addSelectIconToResult mouseleave->search-v2#removeSelectIconInResult");
  div.setAttribute("data-smart-result", typeof (data) === "string" ? data : JSON.stringify(data));
  div.setAttribute("data-type", type);
  div.setAttribute("data-interest-data-key", interestDataKey);
  div.setAttribute("data-interest-data-value", interestDataValue);
  div.setAttribute("data-is-coin-search-result", type === "Coin" && !trending);
  div.setAttribute("data-analytics-event", trending ? SELECT_SEARCH_HIGHLIGHTS : SELECT_SEARCH_EVENT);
  div.setAttribute("data-analytics-event-properties", JSON.stringify(searchCategoryEventProperties(type, analyticsPropertyId, trending)));

  div.innerHTML = `
    <a href="${url}" class="tw-flex tw-justify-between tw-px-2 tw-py-3 tw-rounded-lg">
      <div class="tw-flex tw-items-center">
        ${thumbnail}
        <div class="tw-text-gray-700 tw-text-sm tw-font-semibold hover:tw-text-gray-900 dark:tw-text-moon-100 dark:hover:tw-text-moon-50 tw-mr-1">${title}</div>
        <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-regular tw-flex-grow tw-mt-0.5">${subtitle ?? ""}</div>
      </div>
      <div class="tw-flex tw-items-center" id="rightText">
        ${suffix ?? ""}
      </div>
      <div class="tw-hidden tw-flex-row tw-items-center tw-gap-1" id="rightTextButton">
        <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium">
          ${I18n.t("search.select")}
        </div>
        <span class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-gray-400 dark:tw-bg-moon-500 tw-px-1.5 tw-py-0.5 !tw-px-2">
          <div class="tw-text-xs tw-leading-4 tw-text-white dark:tw-text-moon-50 tw-font-medium">
            <i class="far fa-fa-regular fa-arrow-turn-down-left"></i>
          </div>
        </span>
      </div>
    </a>
  `;

  return div.outerHTML;
};


/**
 * @param {string} type
 * @param {*} data
 */
export const buildShowMoreLink = (type, data) => {
  if (!data || !data.length) {
    return "";
  }

  return `
    <div class="gecko-search-show-more gecko-override-links-primary tw-font-medium">
      <a class="tw-mt-2 tw-mb-3 tw-inline-block" data-action="search-v2#showMore" data-type="${type}">${I18n.t("labels.show_more")}</a>
    </div>
  `;
}
