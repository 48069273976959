import { Controller } from "stimulus";
import { getCSRFToken } from "../../util";
import { EventPortfolioCreated, GeckoPrimerInputError } from "../../events";

export default class extends Controller {
  static targets = ["input", "iconSelector"];

  connect() {
    this.portfolioId = +this.element.dataset.portfolioId;
    this.mode = this.portfolioId ? "modify" : "new";
  }

  handleInput(e) {
    const target = e.currentTarget || e;
    const value = target.value;

    if (!value) {
      target.dispatchEvent(GeckoPrimerInputError(true, I18n.t("portfolio.slug.cannot_be_empty")));
      return false;
    } else {
      target.dispatchEvent(GeckoPrimerInputError(false));
      this.title = value;

      return true;
    }
  }


  process(e) {
    if (!this.handleInput(this.inputTarget)) {
      return;
    }

    const title = this.title;
    const icon = this.iconSelectorTarget.value;

    const url = this._buildUrl();
    const params = { portfolio: { title, icon } }

    const buttonTarget = e.currentTarget;
    buttonTarget.disabled = true;

    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCSRFToken(),
      }
    }).then((response) => response.json())
      .then((json) => this._handleResponse(json))
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        buttonTarget.disabled = false;
      })
  }

  _handleResponse(data) {
    if (!data.message || data.message !== "success") {
      Toaster.toast("An unexpected error occurred: " + data);
      return;
    }

    if (this.mode === "new") {
      let portfolioId = data.portfolio.id;
      if (portfolioId) {
        const event = new CustomEvent(EventPortfolioCreated, { cancelable: true, bubbles: true, detail: data });
        const cancelled = !window.dispatchEvent(event);

        if (!cancelled) {
          window.location.href = `/${I18n.locale}/portfolios/${portfolioId}`;
        } else {
          Modal.hide("new_watchlist");
          Modal.hide("modify_watchlist");
        }
      }
    } else {
      window.location.reload();
    }
  }

  _buildUrl() {
    switch (this.mode) {
      case "new":
        return `/${I18n.locale}/portfolios`;
      case "modify":
        return `/${I18n.locale}/portfolios/${this.portfolioId}`;
      default:
        return null;
    }
  }
}
