import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import { createPopper } from "@popperjs/core";

import initializeInput from "../alpine/input";
import initializeSelect from "../alpine/select";
import initializeCombo from "../alpine/combo";
import initializeCountdown from "../alpine/countdown";
import initializeProgressIndicator from "../alpine/progress_indicator";
import initializeChip from "../alpine/chip";

const initializePlugins = () => {
  Alpine.plugin(collapse);
}

const initializeData = () => {
  Alpine.data("popperable", (variableName) => ({
    popperInstance: null,

    init(attributeName = variableName === "nil" ? "open" : variableName, refElement = null) {
      if (this.$el.dataset.elementSelector)
        refElement = document.querySelector(this.$el.dataset.elementSelector);
      if (!refElement)
        refElement = this.$el.parentElement;

      this.popperInstance = createPopper(refElement, this.$el, { placement: this.$el.dataset.placement || "bottom-start" });
      this.$watch(attributeName, (shown) => { this.handleShownState(shown); });
    },

    handleShownState(shown) {
      this.$nextTick(() => { this.popperInstance.update(); });

      // Enable/disable popper event listeners when shown/hidden.
      this.popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: shown },
        ],
      }));
    },
  }));


  const INITIAL_Z_INDEX = 3200;

  // Track all modals and their states globally with Alpine store.
  Alpine.store("modals", {
    highestZIndex: INITIAL_Z_INDEX,
    dynamicModal: {
      title: null,
      content: null,

      confirmText: null,
      dangerText: null,
      cancelText: null,
      inputField: false,
      inputPlaceholder: null,
    },

    init() {
      window.Modal = {
        show: (modalId) => { this.show(modalId) },
        hide: (modalId) => { this.hide(modalId) },
        toggle: (modalId) => { this.toggle(modalId) },

        // Dynamic modal functions
        info: (title, content) => this.showMessage(title, content),
        confirm: (title, content, confirmText, cancelText) => this.showConfirm(title, content, confirmText, cancelText),
        danger: (title, content, dangerText, cancelText) => this.showDanger(title, content, dangerText, cancelText),
        input: (title, content, confirmText, cancelText, placeholder) => this.showInput(title, content, confirmText, cancelText, placeholder),
      };
    },

    register(modalId) {
      // Prevent clashing modal IDs on the same page.
      if (this[modalId] !== undefined) {
        console.error(`The modal ID '${modalId}' is already in use! Modal IDs must be unique or you will have issues controlling them globally.`);
        return;
      }

      // Modals are always hidden on page load.
      this[modalId] = false;

      // Detect dynamic modal close or clickaway and reject promise.
      if (modalId === "dynamic_modal") {
        Alpine.effect(() => {
          if (this.dynamic_modal === false && this.dynamicModal._cancel) {
            this.dynamicModal._cancel();
          }
        });
      }
    },

    toggle(modalId) {
      if (this[modalId] !== undefined)
        this[modalId] = !this[modalId];
    },

    show(modalId) {
      if (this[modalId] !== undefined)
        this[modalId] = true;
    },

    hide(modalId) {
      if (this[modalId] !== undefined)
        this[modalId] = false;
    },

    showMessage(title, content) {
      this.openDynamicModal(title, content);
    },

    showConfirm(title, content, confirmText, cancelText) {
      return this.openDynamicModal(title, content, confirmText, null, cancelText);
    },

    showDanger(title, content, dangerText, cancelText) {
      return this.openDynamicModal(title, content, null, dangerText, cancelText);
    },

    showInput(title, content, confirmText, cancelText, placeholder) {
      return this.openDynamicModal(title, content, confirmText, null, cancelText, true, placeholder);
    },

    openDynamicModal(title, content, confirmText, dangerText, cancelText, inputField, inputPlaceholder) {
      if (this.dynamic_modal) {
        return;
      }

      this.dynamicModal = { title, content, confirmText, dangerText, cancelText, inputField, inputPlaceholder }
      this.show("dynamic_modal");

      // Use promises to receive feedback from modal.
      if (confirmText || cancelText || inputField) {
        const promise = new Promise((resolve, reject) => {
          this.dynamicModal._confirm = () => resolve({ confirm: true, inputValue: document.getElementById("dynamic-modal-input")?.value });
          this.dynamicModal._cancel = () => resolve({ confirm: false, inputValue: document.getElementById("dynamic-modal-input")?.value });
        });

        // Clean up after promise is settled.
        promise.finally(() => {
          this.hide("dynamic_modal");
          this.dynamicModal._confirm = undefined;
          this.dynamicModal._cancel = undefined;
          document.getElementById("dynamic-modal-input").value = "";
        });
        return promise;
      }
    }
  });

  Alpine.data("modalToggleable", (modalId, modalZIndex) => ({
    hasGlobalState: false,

    init() {
      // Only enable global state control if no parent x-data found.
      // Parent x-data for modals is deprecated and should NOT be used, but logic is included for backwards compatibility.
      if (this[modalId] === undefined) {
        // Define local variable to control modal state.
        this[modalId] = false;

        // Register modal ID to allow modal state control from anywhere.
        this.$store.modals.register(modalId);
        this.hasGlobalState = true;

        // Watch the global state change to manually reflect local state.
        this.$watch(`$store.modals.${modalId}`, (visible) => { this[modalId] = visible });
      }

      this.$watch(modalId, (visible) => this.handleVisibilityChange(visible));
    },

    handleVisibilityChange(visible) {
      if (this.hasGlobalState === true) {
        this.$store.modals[modalId] = visible;
      }

      if (visible) {
        // Add this z-index to top-most modal's z-index so that this modal is above previous modals.
        const targetZIndex = this.$store.modals.highestZIndex + modalZIndex;

        // Set the new z-index value and update the highestZIndex.
        this.$el.style.zIndex = targetZIndex.toString();
        this.$store.modals.highestZIndex = targetZIndex;

        document.documentElement.classList.add("gecko-modal-open");
        document.body.classList.add("gecko-modal-open");
      } else {
        // Update highestZIndex on next tick to prevent race conditions on @click.away.
        this.$nextTick(() => {
          this.$store.modals.highestZIndex -= modalZIndex;

          if (this.$store.modals.highestZIndex <= INITIAL_Z_INDEX) {
            document.documentElement.classList.remove("gecko-modal-open");
            document.body.classList.remove("gecko-modal-open");
          }

          window.dispatchEvent(new CustomEvent("gecko-modal-close", {
            detail: {
              modalId: modalId,
            }
          }));
        });
      }
    },

    // Only close the modal on @click.away if it is the top-most modal.
    // Prevents lower modals from closing upon clicking on active modal.
    handleClickAway(el) {
      if (parseInt(this.$root.style.zIndex) !== this.$store.modals.highestZIndex)
        return;

      this[modalId] = false;
    },
  }));

  Alpine.data("textInput", (inputType) => ({
    canToggle: false,
    input_type: inputType,

    init() {
      this.canToggle = inputType === "password";
    },

    togglePasswordVisibility() {
      if (!this.canToggle) return;

      if (this.input_type === "text") {
        this.input_type = 'password'
      } else {
        this.input_type = 'text'
      }
    },

    showHideInputError(e) {
      e.stopImmediatePropagation();
      if (e.target.id == null || e.detail.show == null) return;

      const input_container = this.$refs.container;
      const error = this.$refs.error;

      if (e.detail.show) {
        error.classList.remove('tw-hidden')
        error.classList.add('tw-block')
        error.textContent = e.detail.errorMessage

        input_container.classList.remove("tw-ring-gray-200", "dark:tw-ring-moon-600")
        input_container.classList.add("tw-ring-danger-500", "dark:tw-ring-danger-500")
      } else {
        error.classList.add('tw-hidden')
        error.classList.remove('tw-block')
        error.textContent = ""

        input_container.classList.remove("tw-ring-danger-500", "dark:tw-ring-danger-500")
        input_container.classList.add("tw-ring-gray-200", "dark:tw-ring-moon-600")
      }
    }
  }));

  initializeInput();
  initializeSelect();
  initializeCombo();
  initializeProgressIndicator();
  initializeChip();
}

export const initializeAlpine = () => {
  if (window.Alpine !== undefined) return;
  window.Alpine = Alpine;

  initializePlugins();
  initializeData();
  initializeCountdown();

  Alpine.start();
}
