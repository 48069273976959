import { EventGeckoPieChartExpanded } from "../../events";

const _formatValue = (value, isPercentage) => {
  value = parseFloat(value);
  if (isNaN(value)) {
    return "NaN";
  }

  let suffix = "";
  if (isPercentage) {
    suffix = "%";
  }

  return value.toFixed(2) + suffix;
}

export const buildChartConfig = (element, data, isPercentage) => {
  return {
    tooltip: {
      useHTML: true,
      borderRadius: 16,
      padding: 0.5,
      formatter() {
        return `<div class="tw-my-2 tw-mx-3">${this.key}: <span class="value">${_formatValue(this.y, isPercentage)}</span></div>`
      }
    },

    series: [{
      colorByPoint: true,
      innerSize: "50%",
      point: {
        events: {
          legendItemClick: (e) => {
            if (e.target.clickExpand === true) {
              e.browserEvent.currentTarget.dispatchEvent(new CustomEvent(EventGeckoPieChartExpanded, { bubbles: true, detail: e.target }));
            }

            return false;
          }
        }
      },
      data: data
    }],


    exporting: false,
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    credits: { enabled: false },

    title: null,
    legend: {
      layout: "vertical",
      verticalAlign: "middle",
      align: "right",
      itemMarginBottom: -1,
      itemMarginTop: -1,
      symbolRadius: 1,
      symbolHeight: 1,
      symbolWidth: 1,
      symbolPadding: 0,
      x: -50,
      useHTML: true,
      labelFormatter() {
        let label = "";
        label += `<div class="gecko-pie-chart-legend">`;
        label += `  <span>`
        label += `    <span class="gecko-pie-chart-legend-symbol highcharts-color-${this.colorIndex}"></span>`;
        label += `    ${this.name}`;
        label += `  </span>`;

        // Render last legend as "View All" if the Pie Chart is expandable.
        if (this.clickExpand === true) {
          label += `  <b class="tw-cursor-pointer">${I18n.t("charts.view_all")}</b>`;
        } else {
          label += `  <b>${_formatValue(this.y, isPercentage)}</b>`;
        }

        label += `</div>`;

        return label;
      }
    },

    plotOptions: {
      series: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: { enabled: false },
        showInLegend: true,
        size: "110%",
      }
    },
    chart: {
      type: "pie",
      renderTo: element.id,
      styledMode: true,
    },

    responsive: {
      rules: [{
        condition: {
          maxWidth: 544
        },
        chartOptions: {
          chart: {
            spacingLeft: 0,
            spacingRight: 0,
            spacingBottom: 0,
          },
          legend: {
            verticalAlign: "bottom",
            align: "center",
            x: 0,
            floating: false,
          },
          plotOptions: {
            series: {
              center: [null, "48%"],
              size: 220,
            }
          }
        }
      }]
    }
  };
};
