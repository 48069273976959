import { pricePercentageTemplate, staticPercentChangeTemplate } from "../price_templates";
import { AD_INTEREST_KEYS } from "../../config";
import { buildResultItem, buildShowMoreLink } from "./item_templates";


/**
 * @param {string} type
 * @param {*} data
 * @param {string|URL} url
 * @param {string?} templateType
 * @param {boolean?} hasMoreResults
 */
export const getListTemplate = (type, data, url, templateType = "search", hasMoreResults = false) => {
  const moreResults = hasMoreResults ? buildShowMoreLink(type, data) : "";
  switch (type) {
    case "coin":
      return getCoinsListTemplate(data, url, templateType) + moreResults;
    case "nft_contract":
      return getNftsListTemplate(data, url, templateType) + moreResults;
    case "category":
      return getCategoriesListTemplate(data, url, templateType) + moreResults;
    case "asset_platform":
      return getChainsListTemplate(data, url) + moreResults;
    case "post":
      return getArticlesListTemplate(data, url) + moreResults;
    case "market":
      return getExchangesListTemplate(data, url) + moreResults;
    default:
      return "";
  }
}

/**
 * @param {*} coins
 * @param {string|URL} url
 * @param {string?} templateType
 */
export const getCoinsListTemplate = (coins, url, templateType = "search") => {
  if (!coins || !coins.length) {
    return "";
  }

  return coins.map((coin) => {
    if (templateType === "trending") {
      coin = coin.item
    }

    let urlPath = new URL(url)
    urlPath.searchParams.set("id", coin.id)
    urlPath.searchParams.set("type", "coin")

    if (templateType === "trending") {
      urlPath.searchParams.set("source", "trending")
    }

    return buildResultItem({
      title: coin.name,
      subtitle: coin.symbol,
      image: coin.thumb,
      suffix: pricePercentageTemplate(coin.data.price_change_percentage_24h),
      url: urlPath,
      data: JSON.stringify(coin),
      type: "Coin",
      trending: templateType === "trending",
      interestDataKey: AD_INTEREST_KEYS["coins"],
      interestDataValue: coin.id,
      analyticsPropertyId: coin.id,
    });
  }).join("");
}


/**
 * @param {*} exchanges
 * @param {string|URL} url
 */
export const getExchangesListTemplate = (exchanges, url) => {
  if (!exchanges || !exchanges.length) {
    return "";
  }

  return exchanges.map((exchange) => {
    let urlPath = new URL(url)
    urlPath.searchParams.set("id", exchange.id)
    urlPath.searchParams.set("type", "exchange")

    return buildResultItem({
      title: exchange.name,
      image: exchange.thumb,
      suffix: exchange.data.trust_score_rank === null ? "" : `#${exchange.data.trust_score_rank}`,
      url: urlPath,
      data: JSON.stringify(exchange),
      type: "Exchange",
      analyticsPropertyId: exchange.id,
    });
  }).join("");
}


/**
 * @param {*} categories
 * @param {string|URL} url
 * @param {string?} templateType
 */
export const getCategoriesListTemplate = (categories, url, templateType = "search") => {
  if (!categories || !categories.length) {
    return "";
  }

  return categories.map((category) => {
    const urlPath = new URL(url)
    urlPath.searchParams.set("id", category.id)
    urlPath.searchParams.set("type", "category")

    let suffix;
    if (templateType === "trending") {
      suffix = staticPercentChangeTemplate(category.market_cap_1h_change, 2);
    } else {
      suffix = pricePercentageTemplate(category.data.market_cap_change_percentage_24h);
    }

    return buildResultItem({
      title: category.name,
      icon: "fa-list-alt",
      suffix: suffix,
      url: urlPath,
      data: JSON.stringify(category),
      type: "Category",
      trending: templateType === "trending",
      interestDataKey: AD_INTEREST_KEYS["categories"],
      interestDataValue: category.data.slug,
      analyticsPropertyId: category.data.slug,
    });
  }).join("");
}


/**
 * @param {*} nfts
 * @param {string|URL} url
 * @param {string?} templateType
 */
export const getNftsListTemplate = (nfts, url, templateType = "search") => {
  if (!nfts || !nfts.length) {
    return "";
  }

  return nfts.map((nft) => {
    let urlPath = new URL(url)
    urlPath.searchParams.set("id", nft.id)
    urlPath.searchParams.set("type", "nft")

    if (templateType === "trending") {
      urlPath.searchParams.set("utm_source", "trending_search")
      urlPath.searchParams.set("utm_medium", "coingecko")
    }


    return buildResultItem({
      title: nft.name,
      subtitle: "",
      image: nft.thumb,
      suffix: staticPercentChangeTemplate(nft.data.floor_price_in_usd_24h_percentage_change, 2),
      url: urlPath,
      data: JSON.stringify(nft),
      type: "Nft",
      trending: templateType === "trending",
      analyticsPropertyId: nft.id,
    });
  }).join("");
}


/**
 * @param {*} articles
 * @param {string|URL} url
 */
export const getArticlesListTemplate = (articles, url) => {
  if (!articles || !articles.length) {
    return "";
  }

  return articles.map((article) => {
    let urlPath = new URL(url);
    urlPath.searchParams.set("id", article.id);
    urlPath.searchParams.set("type", "article");

    return buildResultItem({
      title: article.name,
      icon: "fa-newspaper",
      url: urlPath,
      type: "Article",
    });
  }).join("");
}


/**
 * @param {*} chains
 * @param {string|URL} url
 */
export const getChainsListTemplate = (chains, url) => {
  if (!chains || !chains.length) {
    return "";
  }

  return chains.map((chain) => {
    let urlPath = new URL(url)
    urlPath.searchParams.set("id", chain.id)
    urlPath.searchParams.set("type", "chain")

    return buildResultItem({
      title: chain.name,
      icon: "fa-link",
      suffix: pricePercentageTemplate(chain.data.tvl_change_percentage_30d),
      url: urlPath,
      type: "Chain",
      data: JSON.stringify(chain),
      interestDataKey: AD_INTEREST_KEYS["chains"],
      interestDataValue: chain.api_symbol,
    });
  }).join("");
}
