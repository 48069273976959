import { Controller } from "stimulus";
import Cookies from "js-cookie";
import { copyToClipboardVirtual } from "../../util/copy_to_clipboard";
import { addToMetamask } from "../../util/metamask";
import { setUpdatedCsrfMetaContent } from "../../util";

export default class extends Controller {
  gtagEventTrackLink(e) {
    const element = e.currentTarget;

    const eventCategory = element.dataset.eventCategory;
    const source = element.dataset.source;
    const destination = element.dataset.destination;

    gtag('event', eventCategory, { 'source': source, 'destination': destination });
  }

  async navigateToUrl(e) {
    let url = e.currentTarget.dataset.url;
    let method = e.currentTarget.dataset.method;
    let newTab = e.currentTarget.dataset.newTab;
    let omniauth = !!e.currentTarget.dataset.omniauth;

    if (!url) return;

    if (newTab === "true") {
      window.open(url, "_blank");
      return;
    }

    if (omniauth) {
      Cookies.set("omniauthLocale", I18n.locale);
    }

    if (!method || method.trim().toUpperCase() === "GET") {
      window.location = url;
    } else {
      // Create a fake <a href> tag, but don't inject it into DOM.
      let fakeLink = document.createElement("a");
      fakeLink.href = url;
      fakeLink.dataset.method = method;

      // temp fix for https://3.basecamp.com/4235100/buckets/20228664/todos/6944446413#__recording_7100487587
      await setUpdatedCsrfMetaContent();

      // Pass the fake <a href> tag to the Rails.handleMethod function directly.
      Rails.handleMethod.call(fakeLink, null);
    }
  }

  copyToClipboard(e) {
    let content = e.currentTarget.dataset.content;
    copyToClipboardVirtual(content, e.currentTarget.querySelector(".fa-copy"))
  }

  addToMetamask(e) {
    e.preventDefault();

    const tokenAddress = e.target.getAttribute("data-address");
    const tokenSymbol = e.target.getAttribute("data-symbol");
    const tokenDecimals = e.target.getAttribute("data-decimals");
    const tokenImage = e.target.getAttribute("data-image");
    const chainId = e.target.getAttribute("data-chain-id");

    addToMetamask(tokenAddress, tokenSymbol, tokenDecimals, tokenImage, chainId);
  }

  /**
   * START: App-wide layout logic
   */
  hideAnnouncement() {
    Cookies.set("top_announcement_header_disabled", 1, { expires: 3, path: "/" });
    document.getElementById("announcement").style.display = "none";
  }
  /**
   * END: App-wide layout logic
   */
}
