import { Controller } from "stimulus";
import { EventMoreContentLoaded, EventPriceLoaded, EventTooltipLoaded } from "../../events";

export default class extends Controller {
  static targets = ["content", "loadMoreButton", "totalShownResultsCount"]

  page = 1

  connect() {
    this.availablePages = Math.ceil(+this.element.dataset.contentCount / +this.element.dataset.pageSize)

    this.refreshLoadMoreButton();
    this._overridePageCount();
  }

  async loadMoreContent(e) {
    let button = e.currentTarget;

    let page = this.page + 1;
    let url = new URL(e.currentTarget.dataset.url);

    let hasPrice = e.currentTarget.dataset.hasPrice === "true";
    let hasTooltip = e.currentTarget.dataset.hasTooltip === "true";

    url.searchParams.set("page", page);
    url = url.toString();

    this.loadMoreButtonTarget.disabled = true;

    const response = await fetch(url);
    if (response.status === 200) {
      const html = await response.text();

      if (button.dataset.removeButton === "true") {
        button.remove();
      }

      this.contentTarget.innerHTML += html;
      this.page = page;
      this.refreshLoadMoreButton();

      this.element.dispatchEvent(new CustomEvent(EventMoreContentLoaded, { bubbles: true }));

      if (hasPrice) {
        window.dispatchEvent(new CustomEvent(EventPriceLoaded, { bubbles: true }));
      }

      if (hasTooltip) {
        this.element.dispatchEvent(new CustomEvent(EventTooltipLoaded, { bubbles: true }));
      }

      if (this.hasTotalShownResultsCountTarget) {
        const totalElementsCount = this.contentTarget.childElementCount
        this.totalShownResultsCountTarget.textContent = totalElementsCount.toString()
      }
    }
  }

  refreshLoadMoreButton() {
    if (!this.hasLoadMoreButtonTarget) {
      return;
    }

    this.loadMoreButtonTarget.disabled = false;

    if (this.page >= this.availablePages) {
      this.loadMoreButtonTarget.classList.add("tw-hidden")
    }
  }

  _overridePageCount() {
    if (!("overridePageCount" in this.element.dataset)) {
      return;
    }

    this.page = +this.element.dataset.overridePageCount;
  }
}
