import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["geckoButton", "hideDialogCheckbox", "geckoButtonPing", "modalVersion"];

  connect() {
    if (this._isModalDialogEnabled()) {
      this.geckoButtonTarget.classList.remove("tw-hidden");
    }

    if (this._isPingGeckoGuide()) {
      this.geckoButtonPingTarget.classList.remove("tw-invisible");
      this.geckoButtonPingTarget.classList.add("tw-animate-ping-slow");
    }
  }

  submitHideDialogForm(){
    if(this.hideDialogCheckboxTarget.checked) {
      this._disableModalDialog()
    }

    if (!this.geckoButtonPingTarget.classList.contains("tw-invisible")) {
      this.geckoButtonPingTarget.classList.add("tw-invisible");
    }
  }

  clickGeckoGuide(){
    Cookies.set("clickedGeckoConGeckoModal", true, { expires: 365, path: "" });
    this.geckoButtonPingTarget.classList.remove("tw-animate-ping-slow");
  }

  _disableModalDialog() {
    Cookies.set("disableAnniversaryGeckoModal", true, { expires: 7, path: "" });
  }

  _isModalDialogEnabled() {
    return !Cookies.get("disableAnniversaryGeckoModal");
  }

  _isPingGeckoGuide() {
    // trigger ping if 1) New user cookie session OR 2) modal content changed
    return !Cookies.get("clickedGeckoConGeckoModal");
  }
}
