import { Controller } from "stimulus";
import { EventFocusSearchGlobal } from "../events";

export default class extends Controller {
  focusSearch(){
    window.dispatchEvent(
      new CustomEvent(EventFocusSearchGlobal, {})
    );
  }
}
