import { getListTemplate } from "./list_templates";

export const getSearchResultsTemplate = (title, url, type, data, hasMoreResults = false) => {
  const listTemplate = getListTemplate(type, data, url, "search", hasMoreResults);
  if (!listTemplate) {
    return "";
  }

  return `
    <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-${type}-results">
      <span class="tw-shrink-0 tw-basis-auto">${title}</span>
      <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
    </div>
    <div class="list-reset relevant-results">
      ${listTemplate}
    </div>
  `;
}

export const getTrendingResultsTemplate = (title, url, type, data) => {
  const listTemplate = getListTemplate(type, data, url, "trending");
  if (!listTemplate) {
    return "";
  }

  return `
    <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-${type}-results">
      <span class="tw-shrink-0 tw-basis-auto">${title}</span>
      <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
    </div>
    <div class="list-reset relevant-results">
      ${listTemplate}
    </div>
  `;
}


// Skeleton templates
export const getSkeletonTemplate = () => {
  let template = "";

  // Repeat skeleton group 5 times.
  for (let i = 1; i <= 5; i++) {
    template += `<div class="gecko-skeleton tw-mt-2 tw-mb-2.5"></div> ${getItemSkeletonTemplate(4)} <br/>`;
  }

  return template;
}

export const getSmartResultSkeletonTemplate = () => {
  let template = `<div class="gecko-skeleton"></div>`;

  // Render 4 skeleton items.
  for (let i = 1; i <= 4; i++) {
    template += `<div class="gecko-skeleton-lg tw-w-full tw-my-2.5"></div>`;
  }

  template += `<div class="gecko-skeleton-lg tw-w-full tw-my-2.5 tw-h-28"></div>`;

  return template;
}

export const getItemSkeletonTemplate = (count = 1) => {
  let template = "";

  for (let i = 1; i <= count; i++) {
    template += `<div class="gecko-skeleton-xl tw-h-9 tw-mb-2.5"></div>`;
  }

  return template;
}
