import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["banner"];

  connect() {
    if (this._isBannerDismissed()) {
      return;
    }

    if (this._isMobile() && !this._fromCGAppWebView()) {
      this.bannerTarget.classList.remove("tw-hidden");
    }
  }

  dismiss() {
    Cookies.set("closeAppFlyerBanner", true, { expires: 7, path: "/" });

    this.bannerTarget.classList.add("tw-hidden");
  }

  _isBannerDismissed() {
    return !!Cookies.get("closeAppFlyerBanner");
  }

  _isMobile() {
    return /iphone|android/.test(navigator.userAgent.toLowerCase());
  }

  _fromCGAppWebView() {
    let urlParams = new URLSearchParams(window.location.search);
    return urlParams.has("cgAndroidWebView");
  }
}
