import { Controller } from "stimulus";
import { EventToast } from "../../events";
import { enter, leave } from "el-transition";

const TOAST_DURATION_SHORT_MS = 3000;
const TOAST_DURATION_LONG_MS = 7500;

const TOAST_TEMPLATE = (message, icon = "", dismissable = true) => `
<div id="toast" class="tw-hidden tw-pointer-events-auto tw-w-full tw-max-w-sm tw-overflow-hidden tw-rounded-lg tw-drop-shadow-lg tw-ring-1 tw-ring-gray-200 dark:tw-ring-gray-700 tw-bg-white dark:tw-bg-moon-800 tw-p-4"
     data-transition-enter="tw-transition tw-transition-opacity tw-ease-out tw-duration-300"
     data-transition-enter-start="tw-opacity-0 tw-transform -tw-translate-y-5"
     data-transition-enter-end="tw-opacity-100 tw-transform -tw-translate-y-0"
     data-transition-leave="tw-transition tw-ease-in tw-duration-200"
     data-transition-leave-end="tw-opacity-0 tw-transform -tw-translate-y-full">
  <div class="tw-flex tw-justify-between tw-gap-x-2 tw-text-gray-700 dark:tw-text-moon-100">
    ${icon}
    <div class="tw-flex-1 tw-text-sm tw-font-semibold">
      ${message}
    </div>
    ${dismissable ? '<i class="far fa-fw fa-times tw-ml-2 tw-py-1 tw-cursor-pointer" data-action="click->toaster#untoast"></i>' : ""}
  </div>
</div>
`;


export default class extends Controller {
  static targets = ["content"];

  connect() {
    window.Toaster = {
      toast: this.toast.bind(this),
      bread: () => this.toast("🍞🍞🍞"),
    };
  }

  toast(message, parameters) {
    if (!message) return;

    const actionMessage = parameters?.action?.message;
    const actionOptions = parameters?.action?.options || {};
    const iconDisplay = parameters?.icon !== false;
    const iconClasses = parameters?.icon?.classes || "far fa-check-circle tw-text-success-400 dark:tw-text-success-300";
    const iconOptions = parameters?.icon?.options || {};

    // Longer default duration if call-to-action, allows user to have more interaction time.
    const defaultDuration = actionMessage ? TOAST_DURATION_LONG_MS : TOAST_DURATION_SHORT_MS;
    const toastDuration = parameters?.duration !== undefined ? parameters?.duration : defaultDuration;
    const dismissable = parameters?.dismissable !== false;


    // Create a new blank div and inject the content.
    const contentElement = document.createElement("div");
    contentElement.innerText = message;


    // Build action element dynamically based on given options.
    if (actionMessage) {
      const actionElement = document.createElement("a");
      actionElement.classList.add("tw-block", "tw-cursor-pointer", "tw-mt-1", "tw-underline", "tw-text-inherit", "hover:tw-text-primary-500");
      actionElement.innerText = actionMessage;

      Object.keys(actionOptions).forEach(x => actionElement.setAttribute(x, actionOptions[x]));

      contentElement.appendChild(actionElement);
    }


    // Build icon element dynamically based on given options.
    let iconElement;
    if (iconDisplay) {
      iconElement = document.createElement("i");
      iconElement.classList.add(...iconClasses.split(" "));
      iconElement.classList.add("tw-mt-1", "fa-fw");

      Object.keys(iconOptions).forEach(x => iconElement.setAttribute(x, iconOptions[x]));
    }


    const template = document.createElement("div");
    template.innerHTML = TOAST_TEMPLATE(contentElement.innerHTML, iconElement?.outerHTML, dismissable).trim();

    const element = template.firstChild;
    this.contentTarget.appendChild(element);
    element.classList.remove("tw-hidden");


    enter(element).then(() => {
      // Only set timeout if there's a duration. Allows for static toasts.
      if (toastDuration) {
        setTimeout(() => this.untoast(element), toastDuration);
      }
    });

    return element;
  }

  untoast(e) {
    const element = e?.currentTarget?.closest("#toast") || e;
    if (!element) return;

    leave(element).then(() => element.remove());
  }
}
