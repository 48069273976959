import { Controller } from "stimulus";
import { AD_INTEREST_KEYS } from "../../../config";
import { syncUserDb } from "../../../util/kevel";
import { readConsentConfig } from "../../../util";

const TARGET_COOKIE = "C0004";

export default class extends Controller {
  connect() {
    const pageValue = this.data.get("pageValue");
    const pageKey = this.data.get("pageKey");
    const allowSync = readConsentConfig(TARGET_COOKIE);

    if (!!pageValue && !!pageKey && allowSync) {
      this._addItemToInterest(AD_INTEREST_KEYS[pageKey], pageValue);
    }
  }

  addSearchItemToInterest(e) {
    const key = e.target.parentElement.dataset.interestDataKey;
    const value = e.target.parentElement.dataset.interestDataValue;
    const allowSync = readConsentConfig(TARGET_COOKIE);

    if (key && value && allowSync) {
      this._addItemToInterest(key, value);
    }
  }

  _addItemToInterest(key, value) {
    const localStorageData = localStorage.getItem(key);
    let interestData;

    if (!!value === false) {
      return;
    }

    if (!!localStorageData) {
      interestData = JSON.parse(localStorageData);

      if (interestData.includes(value)) {
        return;
      }

      interestData.push(value);

      // remove the first element if > 50 interest data
      if (interestData.length > 50) {
        interestData.shift();
      }
    } else {
      interestData = [value];
    }

    localStorage.setItem(key, JSON.stringify(interestData));
    syncUserDb();
  }
}
