import { Controller } from "stimulus";
import { buildChartConfig } from "../../template/charts/generic_chart";
import { EventGeckoChartRendered, EventCurrencyChanged } from "../../events";
import { loadHighcharts } from "../../util/load_package";
import { getActiveCurrency } from "../../util/currency";

let Highcharts;
export default class extends Controller {
  // Provided values, from data attributes.
  dataUrl = null;
  dataJson = null;
  chartType = null;

  // Inferred values, not from data attributes.
  chartInstance = null;

  async connect() {
    Highcharts = await loadHighcharts();

    // Only render chart when it's visible.
    if (this.element.offsetParent !== null) {
      this.rerenderChart();
    }

    // Prevents rerender value from being set before controller listens to it.
    this.element.dataset.genericChartRerenderValue = false;

    window.addEventListener(EventCurrencyChanged, () => this.rerenderChart());
  }


  async getData() {
    let data = null;

    if (this.dataUrl) {
      const dataUrlWithActiveCurrency = this.setActiveCurrencyToUrl(this.dataUrl)
      data = await fetch(dataUrlWithActiveCurrency, { credentials: "same-origin" }).then(res => res.json());
    }

    if (this.dataJson && !data) {
      data = JSON.parse(this.dataJson);
    }

    if (!data) {
      return null;
    }

    // We need to resolve the data here as we can receive data with different JSON structures.
    // If you are using generic chart for a new chart with a different structure, add a new type.
    switch (this.chartType) {
      case "exchange:volumes":
        return data.volumes;
      case "exchange:normalizedVolumes":
        return data.normalized_volumes;
      case "exchange:openInterest":
        return data.open_interest
      case "data":
        return data.data;
      default:
        return data;
    }
  }


  renderChart() {
    this.chartInstance?.showLoading("<i class='far fa-fw fa-spinner-third fa-spin tw-text-2xl'></i>");

    this.getData().then((data) => {
      if (!data) {
        data = [];
      }

      this.chartInstance = Highcharts.StockChart(this.element.id, buildChartConfig(this.element, data, {
        label: this.optionLabel,
        displayMode: this.optionDisplayMode,
        currency: this.optionCurrency,
      }));

      // Inform other controllers that the chart was rendered recently.
      this.element.dispatchEvent(new CustomEvent(EventGeckoChartRendered, {
        bubbles: true,
        detail: { type: "generic", chart: this.chartInstance }
      }));
    });
  }

  rerenderChart() {
    this.dataUrl = this.element.dataset.dataUrl;
    this.dataJson = this.element.dataset.dataJson;
    this.chartType = this.element.dataset.chartType;

    this.optionLabel = this.element.dataset.label;
    this.optionDisplayMode = this.element.dataset.displayMode;
    this.optionCurrency = this.element.dataset.currency || getActiveCurrency();

    if (!this.dataUrl && !this.dataJson) {
      return;
    }

    this.renderChart();
  }


  // Watch for re-renders with the "data-generic-chart-rerender-value" attribute.
  // Enables triggering a re-render from other controllers by changing the attribute value.
  // https://stimulus.hotwired.dev/reference/values#change-callbacks
  static values = { rerender: Boolean };

  rerenderValueChanged() {
    if (!Highcharts || this.rerenderValue === false) {
      return;
    }

    this.rerenderValue = false;
    this.rerenderChart();
  }

  setActiveCurrencyToUrl(dataUrl) {
    let formattedUrl = dataUrl
    if (dataUrl.includes("vs_currency=")) {
      const index = dataUrl.indexOf("vs_currency=")
      const existingCurrency = dataUrl.substr(index + "vs_currency=".length, 3)
      formattedUrl = dataUrl.replace(existingCurrency, getActiveCurrency())
    }
    return formattedUrl
  }
}
