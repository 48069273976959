import KevelBaseController from "./kevel_base_controller";
import { AD_TEMPLATES } from "../../../template/ad_templates";
import { sendImpression } from "../../../util/kevel";
import showAds from "../../../util/show_ads";

export default class extends KevelBaseController {
  static targets = ["body"];

  connect() {
    this.clicked = [];
    this.adData = null;
  }

  async sendImpression(e) {
    if (showAds()) {
      this.adProp = this.getAdProp();

      if (this.adDataNotLoaded()) {
        await this.loadAdData();
      }

      if (this.adDataNotNull()) {
        this._renderAdAndSendImpression(e);
      }
    }
  }

  _renderAdAndSendImpression(e) {
    const templateName = this.adProp.templateName;
    const clickedButtonKeyword = e.target.dataset.keyword;
    const adNotSupportMessage = this.bodyTarget.dataset.adNotSupportMessage;

    this.adData.forEach(async adData => {
      const adContentDataKeyword = adData.adContentData["ctKeyword"];
      if (adContentDataKeyword !== undefined) {
        if (adContentDataKeyword.includes("\n")) {
          const keywords = adContentDataKeyword.split('\n');
          keywords.forEach(async keyword => {
            await this._injectBodyAndSendImpression(keyword, adData, clickedButtonKeyword, templateName);
          });
        } else {
          await this._injectBodyAndSendImpression(adContentDataKeyword, adData, clickedButtonKeyword, templateName);
        }
      }
    });

    this.bodyTargets.forEach(body => {
      if (body.innerHTML.includes("fa-spin")) {
        body.innerHTML = `<div class="tw-mx-4">
          ${adNotSupportMessage}
        </div>`
      }
    });
  }

  async _injectBodyAndSendImpression(keyword, adData, clickedButtonKeyword, templateName) {
    const targetBody = document.querySelector(`div[data-coin-row-ads-target="body"][data-keyword="${keyword}"]`);
    if (targetBody !== null && this.unrenderedAdDiv(targetBody)) {
      targetBody.innerHTML = AD_TEMPLATES[templateName](
        adData.link,
        adData.impressionLink,
        adData.adContentData,
        targetBody.dataset.cgLogoUrl,
        targetBody.dataset.sponsored,
      );

      if (clickedButtonKeyword === keyword && !this.clicked.includes(clickedButtonKeyword)) {
        this.clicked.push(clickedButtonKeyword);
        if (await sendImpression(adData.impressionLink) === false) {
          console.log("send impression failed: ", clickedButtonKeyword)
        }
      }
    }
  }
}
