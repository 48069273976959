import { Controller } from "stimulus";
import { getAdData } from "../../../util/kevel";
import { throttle } from "../../../util";

export default class extends Controller {
  async loadAdData() {
    this.adData = await getAdData(this.adProp.requestBody);
  }

  adDataNotNull() {
    return this.adData[0].link !== null;
  }

  getAdProp() {
    return JSON.parse(this.bodyTarget.dataset.adProp);
  }

  adDataNotLoaded() {
    return !this.adData;
  }

  viewportAd(){
    return this.bodyTarget.dataset.viewportAd === "true";
  }

  loadAdIfAdAtViewport() {
    const lazyLoad = () => {
      if (
        this.bodyTarget.getBoundingClientRect().top <= window.innerHeight &&
        this.bodyTarget.getBoundingClientRect().bottom >= 0
      ) {
        this.sendImpression();
      }
    };

    // add throttle here to prevent scroll event recurring
    document.addEventListener("scroll", throttle(lazyLoad, 500));
    window.addEventListener("orientationchange", throttle(lazyLoad, 500));
  }

  attachKeywordToRequestBody() {
    this.adProp.requestBody.keywords = [this.bodyTarget.dataset.keyword];
  }

  dataKeywordExist() {
    return this.bodyTarget.dataset.keyword !== undefined;
  }

  unrenderedAdDiv(element) {
    return element?.querySelector(".fa-spin");
  }
}
