import { castToTarget, castNullToText, deltaTag } from "../util";
import { pricePercentageTemplate, priceTemplate } from "./price_templates"

export const getSmartResultHtmlByType = (e) => {
  const target = castToTarget(e);
  let type = target.dataset.type;
  const data = target.dataset.smartResult;

  switch (type) {
    case "Coin":
      return coinTemplate(JSON.parse(data));
    case "Nft":
      return nftTemplate(JSON.parse(data));
    case "Exchange":
      return exchangeTemplate(JSON.parse(data));
    case "Category":
      return categoryTemplate(JSON.parse(data));
    case "GT":
      return gtTemplate(JSON.parse(data));
    case "Chain":
      return chainTemplate(JSON.parse(data));
    default:
      return "";
  }
}

const coinTemplate = (coin) => {
  return `
    <div>
      <div class="tw-mb-5">
        <div class="tailwind-reset">
          ${_title(I18n.t("search.ticker_stats", { crypto_symbol: coin.symbol.toUpperCase() }))}
          <div class="tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 dark:tw-divide-moon-700">
            ${_listItem(I18n.t("search.rank"), castNullToText(coin.market_cap_rank, "-"))}
            ${_listItem(I18n.t("search.price"), priceTemplate(coin.data.price, coin.data.price_btc, coin.coin_id, coin.symbol))}
            ${_listItem(I18n.t("search.24h%"), pricePercentageTemplate(coin.data.price_change_percentage_24h))}
            ${_listItem(I18n.t("search.market_cap"), priceTemplate(coin.data.market_cap, coin.data.market_cap_btc))}
            ${_listItem(I18n.t("search.24h_trading_volume"), priceTemplate(coin.data.total_volume, coin.data.total_volume_btc))}
            ${_listItem(I18n.t("search.last_7_days"), "")}
          </div>
        </div>
      </div>
      <div class="tw-mb-5">
        ${_sparkline(castNullToText(coin.data.sparkline, "-"))}
        <div class="tw-mt-3 tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700">
        </div>
      </div>
      ${ _description(castNullToText(coin.data.content?.title, ""), castNullToText(coin.data.content?.description, "")) }
    </div>`
};

const nftTemplate = (nft) => {
  return `
    <div>
      <div class="tw-mb-5">
        <div class="tailwind-reset">
          ${_title(I18n.t("search.ticker_stats", { crypto_symbol: nft.symbol.toUpperCase() }))}
          <div class="tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 dark:tw-divide-moon-700">
            ${_listItem(I18n.t("search.floor_price"), castNullToText(nft.data.floor_price, "-"))}
            ${_listItem(I18n.t("search.24h_volume"), castNullToText(nft.data.h24_volume, "-"))}
            ${_listItem(I18n.t("search.24h_average_sale_price"), castNullToText(nft.data.h24_average_sale_price, "-"))}
            ${_listItem(I18n.t("search.last_7_days"), "")}
          </div>
        </div>
      </div>
      <div class="tw-mb-5">
        ${_sparkline(nft.data.sparkline)}
        <div class="tw-mt-3 tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700">
        </div>
      </div>
      ${_description(castNullToText(nft.data.content?.title, ""), castNullToText(nft.data.content?.description, ""))}
    </div>`
};

const exchangeTemplate = (exchange) => {
  return `
    <div>
      <div class="tw-mb-5">
        <div class="tailwind-reset">
          ${_title(exchange.name)}
          <div class="tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 dark:tw-divide-moon-700">
            ${_listItem(I18n.t("search.type"), exchange.data.centralized ? I18n.t("search.centralized_exchange") : I18n.t("search.decentralized_exchange"))}
            ${_listItem(I18n.t("search.24h_volume"), priceTemplate("", exchange.data.h24_volume_btc))}
            ${_listItem(I18n.t("search.trust_score"), `${castNullToText(exchange.data.trust_score, "-")}/10`)}
          </div>
        </div>
      </div>
    </div>`
};

const categoryTemplate = (category) => {
  let label;
  let percentege;

  if (category.market_cap_1h_change) {
    label = I18n.t("search.1h%")
    percentege = deltaTag(category.market_cap_1h_change, 2)
  } else {
    label = I18n.t("search.24h%")
    percentege = pricePercentageTemplate(category.data.market_cap_change_percentage_24h)
  }

  return `
    <div>
      <div class="tw-mb-5">
        <div class="tailwind-reset">
          ${_title(category.name)}
          <div class="tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 dark:tw-divide-moon-700">
            ${_listItem(I18n.t("search.market_cap"), priceTemplate("", category.data.market_cap_btc))}
            ${_listItem(I18n.t("search.24h_volume"), priceTemplate("", category.data.total_volume_btc))}
            ${_listItem(label, percentege)}
          </div>
        </div>
      </div>
      <div class="tw-mb-5">
        ${_sparkline(category.data.sparkline)}
      </div>
    </div>`
};

const gtTemplate = (dex) => {
  let liquidityFormatted = "";
  const liquidity = dex.liquidity;
  const minLiquidity = 100000;

  if (liquidity > minLiquidity) {
    liquidityFormatted = `$${(liquidity / 1000000).toFixed(2)}M`
  } else if (liquidity > 1000 && liquidity < 100000) {
    liquidityFormatted = `$${(liquidity / 1000).toFixed(2)}k`
  } else {
    liquidityFormatted = `$0`
  }

  return `
    <div>
      <div class="tw-mb-5">
        <div class="tailwind-reset">
          ${_title(I18n.t("search.ticker_stats", { crypto_symbol: dex.name }))}
          <div class="tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 dark:tw-divide-moon-700">
            ${_listItem(I18n.t("search.chain"), dex.network_name)}
            ${_listItem(I18n.t("search.liquidity"), liquidityFormatted)}
            ${_listItem(I18n.t("search.price"), `$${dex.base_token_price_usd}`)}
            ${_listItem(I18n.t("search.dex"), dex.dex_name)}
          </div>
        </div>
      </div>
    </div>`
};

const chainTemplate = (chain) => {
  let top3NftsImages = "";
  for (let nft of chain.data.top_chain_nfts) {
    if (nft.image !== "missing_standard.png") {
      top3NftsImages += `<img src="${nft.image}" class="tw-w-6 tw-mr-2" loading="lazy"/>`
    }
  }

  let top3CoinsImages = "";
  for (let coin of chain.data.top_gainer_coins) {
    if (coin.image !== "missing_standard.png") {
      top3CoinsImages += `<img src="${coin.image}" class="tw-w-6 tw-mr-2" loading="lazy"/>`
    }
  }

  return `
    <div>
      <div class="tw-mb-5">
        <div class="tailwind-reset">
          ${_title(chain.name)}
          <div class="tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 dark:tw-divide-moon-700">
            ${_listItem(I18n.t("search.total_value_locked"), priceTemplate("", chain.data.total_value_locked_btc))}
            ${_listItem(I18n.t("search.24h_volume"), priceTemplate("", chain.data.total_volume_btc))}
            ${_listItem(I18n.t("search.top_gainers"), top3CoinsImages)}
            ${_listItem(I18n.t("search.top_nft"), top3NftsImages)}
            ${_listItem(I18n.t("search.30d%"), pricePercentageTemplate(chain.data.tvl_change_percentage_30d))}
          </div>
        </div>
      </div>
      <div class="tw-mb-5">
        ${_sparkline(chain.data.sparkline)}
      </div>
    </div>`
};

const _title = (text) => {
  return `
    <div class="tw-text-xs tw-leading-4 tw-text-gray-900 dark:tw-text-moon-50 tw-font-semibold tw-flex-grow">
      ${text}
    </div>`
};

const _listItem = (description, value) => {
  return `
    <div class="tw-flex tw-justify-between tw-py-3">
      <span class="tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium tw-text-xs tw-leading-5">
        ${description}
      </span>
      <div class="tw-pl-2 tw-text-right">
        <span class="tw-text-gray-700 dark:tw-text-moon-100 tw-font-semibold tw-text-xs tw-leading-5">${value}</span>
      </div>
    </div>`;
};

const _sparkline = (link) => {
  return `<img loading="lazy" class="tw-h-20 tw-w-56 tw-ml-6" src="${link}">`;
};

const _description = (title, description) => {
  return `
    <div>
      <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-semibold tw-mb-1">
        ${title}
      </div>
      <div data-view-component="true" class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-regular tw-h-28 tw-overflow-auto">
        ${description}
      </div>
    </div>`;
};
