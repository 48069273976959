const formatterDate = new Intl.DateTimeFormat(I18n.locale, { year: 'numeric', month: 'long', day: 'numeric' });
const formatterMonth = new Intl.DateTimeFormat(I18n.locale, { year: 'numeric', month: 'long' });


export const buildChartConfig = (element, data, options = {}) => {
  let optionDisplayMode = options?.displayMode;
  let optionGroupByMonth = options?.groupByMonth;

  return {
    tooltip: {
      useHTML: true,
      borderRadius: 16,
      padding: 12,
      formatter() {
        let value, total;
        switch (optionDisplayMode) {
          case "formatted":
            value = this.point.y.toLocaleString(I18n.locale);
            total =this.point.stackTotal.toLocaleString(I18n.locale);
            break;

          default:
            value = this.point.y.toString();
            total = this.point.stackTotal.toString();
            break;
        }

        const formatter = optionGroupByMonth ? formatterMonth : formatterDate;

        return `
          <div class="title">${formatter.format(this.x)}</div>
          <div>${this.point.series.name}: <span class="value">${value}</span></div>
          <div>${I18n.t("labels.total")}: <span class="value">${total}</span></div>
        `;
      }
    },

    series: data,
    xAxis: { type: "datetime" },
    yAxis: {
      title: false,
      opposite: false,
      showLastLabel: true,
      labels: {
        reserveSpace: true,
        formatter() {
          switch (optionDisplayMode) {
            case "formatted":
              return this.value.toLocaleString();
            default:
              return this.value.toString();
          }
        }
      },
    },


    title: false,
    exporting: false,
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    credits: { enabled: false },


    plotOptions: {
      column: {
        stacking: "normal",
        dataGrouping: {
          enabled: optionGroupByMonth,
          forced: true,
          units: [['month', [1]]]
        }
      }
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      floating: false,
      shadow: false,
    },
    chart: {
      type: "column",
      panning: false,
      marginTop: 50,
      renderTo: element.id,
      styledMode: true,
    },

    time: { useUTC: false },
  };
}
