import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["new", "old"];

  connect(e) {
    if (this.isFeatureEnabled()) {
      this.newTarget.classList.remove("tw-hidden");
      this.oldTarget.classList.add("tw-hidden");
    } else {
      this.oldTarget.classList.remove("tw-hidden");
      this.newTarget.classList.add("tw-hidden");
    }
  }

  isFeatureEnabled() {
    const randomValue = Math.random();
    return randomValue < +this.newTarget.dataset.releasePercentage;
  }
}
