import { Controller } from "stimulus";
import { setUpdatedCsrfMetaContent } from "../../util";

export default class extends Controller {
  static targets = ["form"];

  async submit(e) {
    e.preventDefault();
    // temp fix for https://3.basecamp.com/4235100/buckets/20228664/todos/6944446413#__recording_7100487587
    await setUpdatedCsrfMetaContent();
    this.formTarget.submit();
  }
}
