import { Controller } from "stimulus";
import { buildChartConfig } from "../../template/charts/stacked_chart";
import { EventGeckoChartRendered, EventCurrencyChanged } from "../../events";
import { loadHighcharts } from "../../util/load_package";
import { getActiveCurrency } from "../../util/currency";

let Highcharts;
export default class extends Controller {
  // Provided values, from data attributes.
  dataUrl = null;

  // Inferred values, not from data attributes.
  chartInstance = null;

  async connect() {
    Highcharts = await loadHighcharts();
    window.addEventListener(EventCurrencyChanged, () => this.rerenderChart());

    // Prevents rerender value from being set before controller listens to it.
    this.element.dataset.stackedChartRerenderValue = false;
    this.rerenderChart();
  }


  // Expected data structure:-
  //
  // [
  //   {
  //     "data": [[1715652787, 184], ...],
  //     "name": "Name"
  //   },
  //   ...
  // ]
  async getData() {
    let data = null;

    if (this.dataUrl) {
      data = await fetch(this.dataUrl).then(res => res.json());
    }

    if (!data) {
      return null;
    }


    // We need to resolve the data here as we can receive data with different JSON structures.
    // If you are using stacked chart for a new chart with a different structure, add a new type.
    switch (this.chartType) {
      default:
        return data;
    }
  }


  renderChart() {
    this.chartInstance?.showLoading("<i class='far fa-fw fa-spinner-third fa-spin tw-text-2xl'></i>");

    this.getData().then((data) => {
      if (!data) {
        data = [];
      }

      console.log(data);

      this.chartInstance = Highcharts.chart(this.element.id, buildChartConfig(this.element, data, {
        displayMode: this.optionDisplayMode,
        groupByMonth: this.optionGroupByMonth === "true",
      }));

      // Inform other controllers that the chart was rendered recently.
      this.element.dispatchEvent(new CustomEvent(EventGeckoChartRendered, {
        bubbles: true,
        detail: { type: "stacked", chart: this.chartInstance }
      }));
    });
  }

  rerenderChart() {
    this.dataUrl = this.element.dataset.dataUrl;
    this.chartType = this.element.dataset.chartType;

    this.optionDisplayMode = this.element.dataset.displayMode;
    this.optionGroupByMonth = this.element.dataset.groupByMonth;

    if (this.dataUrl) {
      this.renderChart();
    }
  }


  // Watch for re-renders with the "data-stacked-chart-rerender-value" attribute.
  // Enables triggering a re-render from other controllers by changing the attribute value.
  // https://stimulus.hotwired.dev/reference/values#change-callbacks
  static values = { rerender: Boolean };

  rerenderValueChanged() {
    if (!Highcharts || this.rerenderValue === false) {
      return;
    }

    this.rerenderValue = false;
    this.rerenderChart();
  }
}
