import { Controller } from "stimulus";
import { updateCsrfMeta, setUpdatedCsrfMetaContent } from "../../util";

export default class extends Controller {
  static targets = ["form"];

  async connect() {
    const csrfMeta = await updateCsrfMeta();

    const csrfParamMeta = document.createElement("meta");
    csrfParamMeta.setAttribute("name", "csrf-param");
    csrfParamMeta.setAttribute("content", csrfMeta.param);
    document.head.appendChild(csrfParamMeta);

    const csrfTokenMeta = document.createElement("meta");
    csrfTokenMeta.setAttribute("name", "csrf-token");
    csrfTokenMeta.setAttribute("content", csrfMeta.token);
    document.head.appendChild(csrfTokenMeta);

    // add latest CSRF token to user sign in modal
    this._assignAuthenticityTokenToModalForm();

    await setUpdatedCsrfMetaContent(csrfMeta);
  }

  _assignAuthenticityTokenToModalForm() {
    this.formTargets.forEach(target => {
      var tokenHiddenField = document.createElement("input");
      tokenHiddenField.setAttribute("type", "hidden");
      tokenHiddenField.setAttribute("name", "authenticity_token");
      target.appendChild(tokenHiddenField);
    });
  }
}
