export function copyToClipboard(textElement, buttonIconElement) {
  textElement.select();
  textElement.setSelectionRange(0, 99999); /*For mobile devices*/
  document.execCommand("copy");

  if (buttonIconElement) {
    buttonIconElement.classList.remove("fa-copy");
    buttonIconElement.classList.add("fa-check-circle", "tw-text-success-500", "dark:tw-text-success-400");

    setTimeout(() => {
      buttonIconElement.classList.add("fa-copy");
      buttonIconElement.classList.remove("fa-check-circle", "tw-text-success-500", "dark:tw-text-success-400");
    }, 5000);
  }
}

export function copyToClipboardVirtual(content, buttonIconElement) {
  let virtualElement = document.createElement("textarea");
  document.body.appendChild(virtualElement);
  virtualElement.value = content;

  copyToClipboard(virtualElement, buttonIconElement);

  document.body.removeChild(virtualElement);
}
