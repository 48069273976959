import { Controller } from "stimulus";
import mixpanel from "mixpanel-browser";
import { ANALYTICS_USER_LOGGED_IN, ONE_OFF_TRACK_PAGEVIEW_ON_LOGIN } from "../../mixpanel_config";
import {
  identifyUser, optInMixpanel, optInOrOutOfTracking, optOutAnalytics,
  trackLoggedOutPageviews, trackPageviewAfterConsentGiven,
  setMixpanelDeviceIdOnHiddenInputs,
  trackPageviewOnFirstLogin,
  setUTMOnHiddenInputs, untrackUser, trackEvent, unconditionalTrackEvent
} from "../../analytics";
import {
  EventMixpanelInitLoaded,
  EventPerformanceCookieConsentChanged,
  EventSelectStaticDropdownAction
} from "../../events";
import Cookies from "js-cookie";

export default class extends Controller {

  connect() {
    fetch("/accounts/analytics_user_data.json", { credentials: "same-origin" })
      .then(response => response.json())
      .then(data => { this._initMixpanel(data) });
  }

  _initMixpanel(userData) {
    let distinctId = null;
    let debugMode = process.env.NODE_ENV === "development";
    let mixpanelProjectToken = document.body.dataset.mixpanelToken;
    let mixpanelProxyUrl = document.body.dataset.mixpanelProxyUrl;
    let currentUser = userData
    let fullyTrackSession = process.env.NODE_ENV === "development" || process.env.RAILS_ENV === "staging"

    mixpanel.init(mixpanelProjectToken, {
      api_host: mixpanelProxyUrl,
      track_pageview: this._isTrackingPageviews(currentUser),
      debug: debugMode,
      persistence: "localStorage",
      record_sessions_percent: fullyTrackSession ? 100 : 1,
      record_min_ms: 5000,
      loaded: function(mixpanel) {
        window.Mixpanel = mixpanel;

        distinctId = mixpanel.get_distinct_id();
        optInOrOutOfTracking();

        if (currentUser.current_user != null) {
          identifyUser(currentUser)
          trackPageviewOnFirstLogin(currentUser)
        } else {
          _untrackUserIfJustLoggedOut()
        }

        _setUserLoggedInFlag(currentUser)
        _fireMixpanelInitLoadedEvent()
      }
    });

    this._initPerformanceCookieEventListener(currentUser)
    trackLoggedOutPageviews(currentUser);
    setMixpanelDeviceIdOnHiddenInputs(distinctId)
    setUTMOnHiddenInputs();
  }

  _isTrackingPageviews(currentUser) {
    return currentUser.current_user != null && localStorage.getItem(ONE_OFF_TRACK_PAGEVIEW_ON_LOGIN) === "true"
  }

  _initPerformanceCookieEventListener(currentUser) {
    window.addEventListener(EventPerformanceCookieConsentChanged, (e) => {
      let perfCookieEnabled = e.detail.enabled;

      if (perfCookieEnabled) {
        optInMixpanel();
        trackPageviewAfterConsentGiven(currentUser)
      } else {
        optOutAnalytics()
      }
    });
  }
}

function _setUserLoggedInFlag(currentUser) {
  const userLoggedIn = currentUser.current_user != null

  if (userLoggedIn) {
    localStorage.setItem(ANALYTICS_USER_LOGGED_IN, "true")
  } else {
    localStorage.setItem(ANALYTICS_USER_LOGGED_IN, "false")
  }
}

function _untrackUserIfJustLoggedOut() {
  if (Cookies.get("successfully_logged_out") === "true") {
    untrackUser()
    Cookies.remove("successfully_logged_out");
  }
}

function _fireMixpanelInitLoadedEvent() {
  window.dispatchEvent(
    new CustomEvent(EventMixpanelInitLoaded)
  );
}
