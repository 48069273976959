import { Controller } from "stimulus";
import {
  EventCheckUserLogin,
  EventPortfolioCreated,
  EventSelectStaticDropdownAction,
  EventTogglePortfolioSummary,
  EventFetchPortfolio
} from "../../events";
import { createPopper } from "@popperjs/core";
import { useClickOutside } from "stimulus-use";
import { addPortfolioCoinRequest, removePortfolioCoinRequest } from "../../routes/portfolio";
import Cookies from "js-cookie";
import { enter } from "el-transition";

export default class extends Controller {

  static targets = [
    "dropdown", "dropdownContent", "dropdownItem",

    "appPromo", "appPromoDivider", "appPromoCoinImage", "appPromoCoinCryptoSymbol",

    "portfolioDropdownButton", "portfolioDropdownResults"
  ];

  connect() {
    Cookies.remove("portfolioCoinId");
    this.dropdownItems = [];

    window.addEventListener(EventPortfolioCreated, this.handlePortfolioCreated.bind(this));
    window.addEventListener(EventFetchPortfolio, this._fetchPortfolioData.bind(this));
    window.addEventListener(EventCheckUserLogin, e => {
      this.loggedIn = e.detail.userLoggedIn;

      // Populate dropdown with portfolios.
      if (this.loggedIn && this.hasDropdownTarget) {
        useClickOutside(this, { element: this.dropdownTarget });
        fetch(`/${I18n.locale}/portfolios/all_coins`)
          .then(async response => {
            if (!response.ok) {
              throw new Error(`Could not retrieve portfolio data. Error ${response.status}.`);
            }

            const data = await response.json();
            if (!data) {
              throw new Error(`Could not retrieve portfolio data. Invalid portfolio data returned.`);
            }

            this.portfolios = data;
            this.portfolios.forEach(x => this._initDropdownItem(x.title, x.id));

            this._updateAllStars();
          }
        );
      }
    });
  }

  showDropdown(e) {
    this._handlePreventDefault(e);

    const coinId = +e.currentTarget.getAttribute("data-coin-id");
    if (!coinId || !this.hasDropdownTarget) {
      return;
    }

    // Add a minimum 50ms delay before the dropdown can be shown.
    if (Date.now() < (this.dropdownClosedAt + 50)) {
      return;
    }

    if (!this.loggedIn) {
      Cookies.set("portfolioCoinId", coinId);
      return Modal.show("auth_modal");
    }

    if (this.dropdownPopperInstance) {
      return this.hideDropdown(e);
    }

    this._updateDropdownItem(coinId);

    this.dropdownTarget.classList.remove("tw-hidden");
    this.dropdownPopperInstance = createPopper(e.currentTarget, this.dropdownTarget, { placement: "bottom-start" });
    this.dropdownTriggeredBy = e.currentTarget;
    this.dropdownOpenedAt = Date.now();
    this.pageType = this.dropdownTriggeredBy.getAttribute("data-page");
  }

  hideDropdown(e) {
    if (!this.dropdownPopperInstance || !this.hasDropdownTarget) {
      return;
    }

    // Add a minimum 50ms delay before the dropdown can be hidden.
    if (Date.now() < (this.dropdownOpenedAt + 50)) {
      return;
    }

    this.dropdownTarget.classList.add("tw-hidden");
    this.dropdownPopperInstance?.destroy();
    this.dropdownPopperInstance = null;
    this.dropdownTriggeredBy = null;
    this.dropdownClosedAt = Date.now();
    this.appPromoTarget.classList.remove("tw-block");
    this.appPromoTarget.classList.add("tw-hidden");
    this.appPromoDividerTarget.classList.add("tw-hidden");
  }

  handle(e) {
    this.coinIdForNewPortfolio = null;

    let coinId = this.dropdownTriggeredBy.getAttribute("data-coin-id");
    let portfolioId = e.currentTarget.getAttribute("data-portfolio-id");
    if (!coinId || !portfolioId) return;

    let portfolioData = this.portfolios.find(x => x.id == portfolioId);
    if (!portfolioData) return;

    const hasCoin = portfolioData.coin_ids.includes(+coinId);
    if (hasCoin) {
      this._removeCoin(+coinId, +portfolioId);
    } else {
      this._addCoin(+coinId, +portfolioId);
    }
  }

  handleNewPortfolio(e) {
    let coinId = this.dropdownTriggeredBy.getAttribute("data-coin-id");
    if (!coinId) return;

    this.coinIdForNewPortfolio = coinId;
    Modal.show("new_watchlist");
  }

  handlePortfolioCreated(e) {
    if (!this.coinIdForNewPortfolio || !e?.detail?.portfolio) {
      return;
    }

    e.preventDefault();

    this.portfolios.push({ ...e.detail.portfolio, coin_ids: [] });
    this._initDropdownItem(e.detail.portfolio.title, e.detail.portfolio.id);
    this._addCoin(this.coinIdForNewPortfolio, e.detail.portfolio.id);
  }

  _removeCoin(coinId, portfolioId) {
    Modal.confirm(
      I18n.t("portfolio.remove_coin.title"),
      I18n.t("portfolio.remove_coin.body"),
      I18n.t("labels.yes"),
      I18n.t("labels.no")
    ).then((result) => {
      if (!result.confirm) return;

      removePortfolioCoinRequest(
        { coinId, portfolioId },
        (res) => {
          // Update local data to remove coin ID.
          let portfolio = this.portfolios.find(x => x.id === portfolioId)

          let coinIdIndex = portfolio.coin_ids.indexOf(coinId);
          if (coinIdIndex !== -1) {
            portfolio.coin_ids.splice(coinIdIndex, 1);
          }

          Toaster.toast(I18n.t("portfolio.remove_coin.success", { portfolioTitle: portfolio.title }));
          this._updateAllStars();

          if (this.pageType === "coins") {
            let otherPortfoliosHasCoin = this.portfolios.some(x => x.coin_ids.includes(coinId));
            if (!otherPortfoliosHasCoin) {
              this._togglePortfolioSummary({ show: false, trackGAEvent: false });
            }

            this.portfolioDropdownButtonTarget.dispatchEvent(
              new CustomEvent(EventSelectStaticDropdownAction, {
                bubbles: true,
                detail: { item: { value: portfolioId }, action: "remove" },
              })
            );
          }
        })
    });
  }

  _addCoin(coinId, portfolioId) {
    addPortfolioCoinRequest(
      { coinId, portfolioId },
      "POST",
      (res) => {
        let portfolio = this.portfolios.find(x => x.id === portfolioId);
        portfolio.coin_ids.push(res.coin_id);

        Toaster.toast(
          I18n.t("portfolio.add_coin.success", { portfolioTitle: portfolio.title }),
          {
            action: {
              message: I18n.t("portfolio.view_portfolio"),
              options: {
                href: `/${I18n.locale}/portfolios/${portfolioId}`,
              }
            }
          }
        );

        this._updateDropdownItem(res.coin_id);
        this._updateAllStars();

        if (this.pageType === "coins") {
          this._togglePortfolioSummary({ show: true });

          this.portfolioDropdownButtonTarget.dispatchEvent(
            new CustomEvent(EventSelectStaticDropdownAction, {
              bubbles: true,
              detail: {
                item: {
                  value: portfolioId,
                  innerHTML: res.portfolio_title,
                  attributes: {
                    "data-identifier": "portfolio-transaction-dropdown",
                    "data-portfolio-coin-id": res.portfolio_coin_id,
                    "data-action": "click->portfolio-transactions-modal#handlePortfolioSelect",
                    "data-view-component": "true",
                  },
                },
                action: "add"
              },
            })
          );
        }

        this._showAppPromo();
      })
  }

  _initDropdownItem(title, portfolioId) {
    if (!this.dropdownItemBase) {
      this.dropdownItemBase = this.dropdownItemTarget.cloneNode(true);
      this.dropdownItemTarget.remove();
    }

    let element = this.dropdownItemBase.cloneNode(true);

    element.querySelector("span").innerText = title;
    element.setAttribute("data-portfolio-id", portfolioId);
    element.setAttribute("data-action", "click->coin-favorites#handle");

    this.dropdownItems.push(this.dropdownContentTarget.appendChild(element));
  }

  _updateDropdownItem(coinId) {
    if (!coinId) {
      return;
    }

    let portfolioHasCoin = {};
    for (let portfolio of this.portfolios) {
      portfolioHasCoin[portfolio.id] = portfolio.coin_ids.includes(coinId);
    }

    this.dropdownItems.forEach(item => {
      let portfolioId = item.getAttribute("data-portfolio-id");
      if (portfolioId) {
        let icon = item.querySelector("i");
        let checked = portfolioHasCoin[+portfolioId];

        icon.classList.toggle("fa-plus", !checked);
        icon.classList.toggle("fa-check", checked);
        icon.classList.toggle("tw-text-success-500", checked);
        icon.classList.toggle("dark:tw-text-success-400", checked);
      }
    });
  }

  _fetchPortfolioData() {
    fetch(`/${I18n.locale}/portfolios/all_coins`)
      .then(async response => {
          if (!response.ok) {
            throw new Error(`Could not retrieve portfolio data. Error ${response.status}.`);
          }

          const data = await response.json();
          if (!data) {
            throw new Error(`Could not retrieve portfolio data. Invalid portfolio data returned.`);
          }

          this.portfolios = data;
          this._updateAllStars();
        }
      );
  }

  _updateAllStars() {
    const coinIdStar = {}
    this.portfolios.forEach(x => x.coin_ids.forEach(id => coinIdStar[id] = true));

    this.element.querySelectorAll(`i.fa-star`).forEach(star => {
      const coinId = +star.getAttribute("data-coin-id");
      if (!coinId) return;

      const enabled = !!coinIdStar[coinId];

      star.classList.toggle("far", !enabled);
      star.classList.toggle("fas", enabled);
      star.classList.toggle("tw-text-yellow-500", enabled);

      if (!!star.dataset.tooltip) {
        star.dataset.tooltip = enabled ? I18n.t("portfolio.remove_coin.tooltip") : I18n.t("portfolio.add_coin.tooltip");
      }
    });
  }

  _togglePortfolioSummary(options = {}) {
    const { show = false, trackGAEvent = true } = options;

    window.dispatchEvent(
      new CustomEvent(EventTogglePortfolioSummary, {
        bubbles: true,
        detail: {
          show,
          trackGAEvent
        }
      })
    );
  }

  _showAppPromo() {
    const coinId = this.dropdownTriggeredBy.dataset.coinId;
    const url = `/${I18n.locale}/portfolios/app_promo?coin_id=${coinId}`;

    fetch(url, { credentials: "same-origin" })
      .then((response) => response.json())
      .then((data) => {
        if (!data.show_app_promo) {
          return;
        }

        this.appPromoCoinCryptoSymbolTarget.innerText = data.coin_crypto_symbol;
        this.appPromoCoinImageTarget.src = data.coin_image;
        this.dropdownContentTarget.parentElement.classList.remove("tw-max-h-96");

        this.appPromoTarget.classList.remove("tw-hidden");
        this.appPromoTarget.classList.add("tw-block");
        enter(this.appPromoTarget);
        this.appPromoDividerTarget.classList.remove("tw-hidden");
      })
  }

  _handlePreventDefault(e) {
    if(e.currentTarget.dataset.preventDefault) {
      e.preventDefault();
    }
  }
}
