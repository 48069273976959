import { showHideInputError } from "./input";

export default () => {
  Alpine.data("combo", (isDynamic = false, isButton = false) => ({
    value: null,
    open: false,

    init() {
      const initialValue = this.$refs.input.value;
      const buttonLabel = isButton ? this.$refs.content.innerHTML : null;

      // When using button mode, a GeckoPrimer::Form::Input component is rendered, which
      // uses x-data internally. This causes the x-ref to not be available on the combo
      // component's x-data. We need to manually assign it if it's not available.
      if (isButton) {
        this.$refs.textInput ??= this.$root.querySelector("[x-ref='textInput']");
      }

      this.$refs.staticDropdown = this.$refs.dropdown.querySelector(`.gecko-combo-dropdown-static`);
      this.$refs.resultsDropdown = this.$refs.dropdown.querySelector(`.gecko-combo-dropdown-results`);
      this.$refs.dropdownMessage = this.$refs.dropdown.querySelector(".gecko-combo-dropdown-message");
      this.$refs.dropdownLoading = this.$refs.dropdown.querySelector(".gecko-combo-dropdown-loading");

      // Ensures component state is in sync with hidden input's value, can be set programmatically.
      Object.defineProperty(this.$refs.input, "value", {
        get: () => this.value,
        set: (value) => this.value = String(value),
      });

      // Ensure that the text input is in-sync with the dropdown state.
      this.$watch("open", open => {
        // Button mode always has content and textInput visible.
        if (!isButton) {
          this.$refs.textInput.classList.toggle("tw-hidden", !open);
          this.$refs.content.classList.toggle("tw-hidden", open);
        }

        if (open === true) {
          this.$refs.resultsDropdown?.classList?.add("tw-hidden");
          (this.$refs.staticDropdown || this.$refs.dropdownMessage).classList.remove("tw-hidden");

          // Reset search when dropdown is opened.
          this.$refs.textInput.value = null;
          if (!isDynamic) {
            const dropdownMessageTarget = this.$refs.dropdown.querySelector(".gecko-combo-dropdown-message");
            dropdownMessageTarget.classList.add("tw-hidden")

            const elements = this.$refs.dropdown.querySelectorAll(`.gecko-combo-dropdown-static .gecko-combo-dropdown-item`);
            elements.forEach(element => element.classList.remove("!tw-hidden"));
          }
        }
      });


      this.$watch("value", value => {
        // Allows Alpine value to be picked up by browser if within a <form>.
        if (value != null) {
          this.$refs.input.setAttribute("value", value);
        } else {
          this.$refs.input.removeAttribute("value");
        }


        const selectedIconTarget = this.$refs.dropdown.querySelector(`i.gecko-combo-selected`);
        const valueTarget = this.$refs.dropdown.querySelector(`[data-value='${value}']`);
        if (!valueTarget && value != null) {
          return this.value = null;
        }

        if (isButton) {
          this.$refs.content.innerHTML = !value ? buttonLabel : valueTarget.innerHTML;
        } else {
          this.$refs.content.innerHTML = value == null ? "" : valueTarget.innerHTML;
        }


        selectedIconTarget?.remove();
        valueTarget?.insertAdjacentHTML("beforeend", `<i class="fa fa-check gecko-combo-selected"></i>`);

        this.open = false;
        this.$refs.input.dispatchEvent(new CustomEvent("change", {
          bubbles: true,
          detail: {
            targets: {
              content: this.$refs.content,
              value: valueTarget,
            }
          }
        }));
      });


      isDynamic ? this._prepareDynamicCombo() : this._prepareStaticCombo();

      // Use nextTick to prevent race condition, where value is set before change
      // event listeners are registered by Stimulus. Ensures that the change event
      // is fired if a value is set on a combobox upon initial page load.
      this.$nextTick(() => this.value = initialValue);
    },


    handleClick(e) {
      this.open = !this.open;

      if (this.open) {
        // Unhide the element first, as it needs to be visible to be focused.
        // The content element will be unhidden by the watch function above.
        this.$refs.textInput.classList.remove("tw-hidden");

        // We need to focus within the click event as iOS only allows programmatic
        // focus within user-triggered events, such as the click or mousedown event.
        this.$refs.textInput.focus();
      }
    },


    _getItemTemplate(value) {
      const element = document.createElement("div");

      element.classList.add("gecko-combo-dropdown-item");
      element.setAttribute("data-value", value);
      element.setAttribute("x-on:click", `value = ${JSON.stringify(value)}; open = false`);

      return element;
    },


    _prepareDynamicCombo() {
      const staticDropdownTarget = this.$refs.staticDropdown;
      const resultsDropdownTarget = this.$refs.resultsDropdown;
      const dropdownMessageTarget = this.$refs.dropdownMessage;
      const dropdownLoadingTarget = this.$refs.dropdownLoading;

      this.$refs.textInput.addEventListener("input", (e) => {
        this.open = true;

        const query = e.currentTarget.value;
        if (!query) {
          resultsDropdownTarget.classList.add("tw-hidden");
          dropdownLoadingTarget.classList.add("tw-hidden");
          (staticDropdownTarget || dropdownMessageTarget).classList.remove("tw-hidden");

          return;
        }


        staticDropdownTarget?.classList?.add("tw-hidden");
        resultsDropdownTarget.classList.add("tw-hidden");
        dropdownMessageTarget.classList.add("tw-hidden");
        dropdownLoadingTarget.classList.remove("tw-hidden");

        this.$refs.input.dispatchEvent(new CustomEvent("search", {
          detail: {
            query: query,
            template: this._getItemTemplate,
            targets: {
              results: resultsDropdownTarget,
              message: dropdownMessageTarget,
              loading: dropdownLoadingTarget,
            }
          }
        }));
      });
    },

    async _prepareStaticCombo() {
      const Fuse = (await import("fuse.js")).default;

      const dropdownMessageTarget = this.$refs.dropdown.querySelector(".gecko-combo-dropdown-message");
      const elements = this.$refs.dropdown.querySelectorAll(`.gecko-combo-dropdown-static .gecko-combo-dropdown-item`);

      // We have to use a trimmed string, as any whitespace may return wrong search results.
      const elementMap = Array.from(elements).map(element => ({ element, searchString: element.innerText.trim() }))
      const elementsSearch = new Fuse(elementMap, { keys: ["searchString"], threshold: 0.3 });

      this.$refs.textInput.addEventListener("input", (e) => {
        this.open = true;

        const query = e.currentTarget.value;
        if (!query) {
          dropdownMessageTarget.classList.add("tw-hidden");
          elements.forEach(el => el.classList.remove("!tw-hidden"));

          return;
        }


        const results = elementsSearch.search(query);
        dropdownMessageTarget.classList.toggle("tw-hidden", results.length !== 0);

        elements.forEach(el => el.classList.add("!tw-hidden"));
        results.forEach(result => result.item.element.classList.remove("!tw-hidden"));
      });
    },

    showHideInputError(e) {
      showHideInputError(e, this.$refs.container, this.$refs.error);
    }
  }));
}
