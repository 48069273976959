import { dispatchToast } from "../util";

export function resolveSuccess(callback, response) {
  return callback && callback(response);
}

export function resolveError(exception, callback, message) {
  console.error(exception);

  // resolve promise object first.
  if (exception instanceof Promise) {
    exception.then((e)=> callback && callback(e));
  } else {
    callback && callback(exception);
  }

  dispatchToast(exception.toString() || message);
}
