export default {
  async _prepareSearchableCombo() {
    const Fuse = (await import("fuse.js")).default;

    const dropdownMessage = this._gp_refDropdownMessage;
    const elements = this.$refs.dropdown.querySelectorAll(`.gecko-select-dropdown-static .gecko-select-dropdown-item`);

    // We have to use a trimmed string, as any whitespace may return wrong search results.
    const elementMap = Array.from(elements).map(element => ({ element, searchString: element.innerText.trim() }));
    const elementsSearch = new Fuse(elementMap, { keys: ["searchString"], threshold: 0.3 });

    this._gp_refTextInput.addEventListener("input", (e) => {
      this.open = true;

      const query = e.currentTarget.value;
      if (!query) {
        dropdownMessage.classList.add("tw-hidden");
        elements.forEach(el => el.classList.remove("!tw-hidden"));

        return;
      }


      const results = elementsSearch.search(query);
      dropdownMessage.classList.toggle("tw-hidden", results.length !== 0);

      elements.forEach(el => el.classList.add("!tw-hidden"));
      results.forEach(result => result.item.element.classList.remove("!tw-hidden"));
    });
  },


  _handleSearchableClick(e) {
    if (this.open === true) {
      // Unhide the element first, as it needs to be visible to be focused.
      // The content element will be unhidden by the watch function above.
      this._gp_refTextInput.classList.remove("tw-hidden");

      // We need to focus within the click event as iOS only allows programmatic
      // focus within user-triggered events, such as the click or mousedown event.
      this._gp_refTextInput.focus();
      this.$nextTick(() => this._gp_refTextInput.focus());
    }
  },
};
