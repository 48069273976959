export const isDownKey = keyboardEvent => {
  return keyboardEvent.key === "ArrowDown" || keyboardEvent.key === "Down";
};
export const isUpKey = keyboardEvent => {
  return keyboardEvent.key === "ArrowUp" || keyboardEvent.key === "Up";
};
export const isEscKey = keyboardEvent => {
  return keyboardEvent.key === "Escape" || keyboardEvent.key === "Esc";
};
export const isEnterKey = keyboardEvent => {
  return keyboardEvent.key === "Enter";
};
export const isSlashKey = keyboardEvent => {
  return keyboardEvent.key === "Divide" || keyboardEvent.key === "/";
};