import { getCSRFToken } from "../util";
import { resolveError } from "../helpers/routes";

export function getPortfolioNftContracts(validate, success) {
  fetch("/en/nft-portfolios/nfts", {
    credentials: "same-origin",
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      if (!validate(response)) {
        return;
      }

      return response.json();
    })
    .then(res => {
      if (success) {
        success(res);
      }
    });
}

export function addPortfolioNftContractRequest(params, success) {
  fetch(`/en/nft-portfolios/${params.nftPortfolioId}/nfts/${params.nftContractId}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken()
    },
    method: "POST",
    credentials: "same-origin",
    body: JSON.stringify({ source: params.source })
  })
    .then(response => response.json())
    .then(res => {
      if (success) {
        success(res);
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    });
}

export function removePortfolioNftContractRequest(params, success) {
  fetch(`/en/nft-portfolios/${params.nftPortfolioId}/nfts/${params.nftContractId}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken()
    },
    method: "DELETE",
    credentials: "same-origin",
    body: JSON.stringify({ source: params.source })
  })
    .then(response => response.json())
    .then(res => {
      if (success) {
        success(res);
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    });
}

export function searchPortfolioNftContractRequest(params, success) {
  fetch(`${params.searchPath}?query=${params.nftContractSearchInput}`, {
    credentials: "same-origin"
  })
    .then(response => response.text())
    .then(res => {
      if (success) {
        success(res);
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    });
}