import { getActiveCurrency } from "../util/currency";

const _QUADRILLION = 1e15;
export const staticPercentChangeTemplate = (value, decimalPlaces = 1) => {
  let percentage = +value;
  let classes;
  let iconClasses;
  let percentageDisplay;

  if (percentage >= 0) {
    classes = "tw-text-success-500 dark:tw-text-success-400"
    iconClasses = "fas fa-caret-up"
  } else {
    classes = "tw-text-danger-500"
    iconClasses = "fas fa-caret-down"
    percentage = Math.abs(percentage)
  }

  if (percentage < _QUADRILLION) {
    percentageDisplay = percentage.toLocaleString(I18n.locale, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
  } else {
    percentageDisplay = percentage.toExponential();
  }

  return `    
    <span class="${classes} tw-break-words">
      <i class="${iconClasses} tw-mr-1"></i>
      ${percentageDisplay}%
    </span>`;
}

export const pricePercentageTemplate = (data) => {
  if (data === null || data === undefined || data === "-" || JSON.stringify(data) === "{}") {
    return "-"
  }

  let percentage = Number(data[getActiveCurrency()]).toFixed(2);
  let classes;
  let iconClasses;

  if (percentage > 0) {
    classes = "tw-text-success-500 dark:tw-text-success-400"
    iconClasses = "fas fa-caret-up"
  } else {
    classes = "tw-text-danger-500"
    iconClasses = "fas fa-caret-down"
    percentage = percentage * -1
  }

  return `
    <span class="${classes} tw-break-words tw-text-xs"
      data-json='${JSON.stringify(data)}'
      data-up-class="tw-text-success-500 dark:tw-text-success-400"
      data-down-class="tw-text-danger-500"
      data-24h="false"
      data-percent-change-target="percent"
      data-show-solid-arrow="true"
      data-is-caret="true"
      data-formatted="true">
      <i class="${iconClasses} tw-mr-1"></i>${percentage}%
    </span>`
};

export const priceTemplate = (price, price_btc, coinId = "", coinSymbol = "", noDecimal = "") => {
  if (price === null || price === undefined) {
    return "-"
  };

  return `
    <span class="no-wrap tw-text-xs"
      data-price-target="price"
      data-coin-id="${coinId}"
      data-coin-symbol="${coinSymbol}"
      data-no-decimal="${noDecimal}"
      data-price-btc="${price_btc}">
      ${price}
    </span>`
};
