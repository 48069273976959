import { Controller } from "stimulus";
import { getCSRFToken, setUpdatedCsrfMetaContent } from "../../util";
import { showFormInputError, hideFormInputError } from "../../util/gecko_primer";

export default class extends Controller {
  _nonInputNewsletterSubscription = null;

  static targets = ["emailInput"];

  async subscribeNewsletter(e) {
    e.preventDefault();

    const buttonTarget = e.currentTarget;
    buttonTarget.disabled = true;

    this._nonInputNewsletterSubscription = !!buttonTarget.dataset.nonInputNewsletter;
    await setUpdatedCsrfMetaContent()

    let params = {
      email: this._getUserEmail(buttonTarget),
      campaignId: buttonTarget.dataset.campaignId
    };

    fetch("/newsletter/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken()
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        // TODO: Remove if not required. Seems legacy.
        window._vis_opt_queue = window._vis_opt_queue || [];
        window._vis_opt_queue.push(function() { _vis_opt_goal_conversion(200); });

        return response.json();
      })
      .then(data => {
        if (data.httpStatus === 202) {
          this._onSuccessSubscription();
          return;
        }

        let errorMessage;
        if (data.httpStatus === 406) {
          errorMessage = "Invalid campaign ID. Please try later."
        } else if (data.context.length) {
          errorMessage = data.context[0]["errorDescription"];
        } else {
          errorMessage = data.message;
        }

        this._onErrorSubscription(errorMessage);
      })
      .catch(() => {
        let catchMessage = "Unknown error. Please try later."
        this._onErrorSubscription(catchMessage);
      })
      .finally(() => {
        buttonTarget.disabled = false;
      });
  }

  _getUserEmail(buttonTarget) {
    if (this._nonInputNewsletterSubscription) {
      return buttonTarget.dataset.email;
    } else {
      return this.emailInputTarget.value;
    }
  }

  _onSuccessSubscription() {
    Toaster.toast(I18n.t("newsletter.success"), {
      icon: { classes: "fas fa-check tw-text-success-500" }
    });

    if (!this._nonInputNewsletterSubscription) {
      hideFormInputError(this.emailInputTarget);
    }
  }

  _onErrorSubscription(message){
    if (this._nonInputNewsletterSubscription) {
      Toaster.toast(message, {
        icon: { classes: "fas fa-times tw-text-danger-500" }
      });
    } else {
      showFormInputError(this.emailInputTarget, message);
    }
  }
}
