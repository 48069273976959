import { Controller } from "stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["rowCardBody"];

  connect() {
    useClickOutside(this);
  }

  clickOutside(e) {
    this.hideCoinRowCardPopup();
  }

  hideCoinRowCardPopup() {
    this.rowCardBodyTarget.removeAttribute("open");
  }
}
