import { Controller } from "stimulus";
import showAds from "../../../util/show_ads";

const popOverLists = ["buysell-button-01", "buysell-button-02", "buysell-button-03",
                      "longshort-button-01", "longshort-button-02", "longshort-button-03",
                      "earnloan-button-01", "earnloan-button-02", "earnloan-button-03"]
export default class extends Controller {
  static targets = ["bsSponsored", "bsPopover", "lsPopover", "elPopover", "coinRowBuy", "sponsoredPopularCoin", "sponsoredSearch", "sponsoredWallet", "nftRowBuy", "nftBsPopover", "selectedArticle"];

  connect() {
    if (showAds()) {
      window.BSANativeCallback = (a) => {
        let element = document.getElementById(a.options.target.slice(1))

        if (popOverLists.includes(element.id)) {
          if (!a.ads.length) {
            element.hidden = true
          }
        }
      };

      if (this.hasBsSponsoredTarget) {
        this.bsSponsoredTargets.forEach((el) => {
          el.onload = this.loadSponsoredExchangeAds(el);
        });
      }

      if (this.hasCoinRowBuyTarget) {
        this.coinRowBuyTargets.forEach((el) => {
          el.onload = this.loadCoinRowAds(el);
        });
        _bsa.loadBatch();
      }

      if (this.hasNftRowBuyTarget) {
        this.nftRowBuyTargets.forEach((el) => {
          el.onload = this.loadNftRowAds(el);
        });
        _bsa.loadBatch();
      }

      if (this.hasNftBsPopoverTarget) {
        this.nftBsPopoverTargets.forEach((el) => {
          el.onload = this.loadNftPopOverAds(el);
        });
      }

      if (this.hasSponsoredPopularCoinTarget) {
        this.sponsoredPopularCoinTargets.forEach((el) => {
          el.onload = this.loadSponsoredPopularCoinsAds(el);
        });
      }

      if (this.hasSponsoredWalletTarget) {
        this.sponsoredWalletTargets.forEach((el) => {
          el.onload = this.loadSponsoredWalletAds(el);
        });
      }

      if (this.hasSelectedArticleTarget) {
        this.selectedArticleTargets.forEach((el, idx) => {
          el.id = `selected-article-${idx}`;
          el.onload = this.loadSelectedArticleAds(el);
        })
      }

      this.loadSponsoredBoxAds();
      setTimeout(() => this._bsaInitTokens(), 5000);
    }
  }

  loadLazily(e) {
    let element = e.currentTarget;
    let targetType = element.dataset.adsTargetType;

    this[targetType  + "Targets"].forEach((el) => {
      if (el.dataset.loaded === "true") return;

      if (targetType === "sponsoredSearch") {
        el.onload = this.loadSponsoredSearchAds(el);
        el.dataset.loaded = "true";
      } else if (targetType.endsWith("Popover")) {
        el.onload = this.loadPopOverAds(el);
        el.dataset.loaded = "true";
      }
    });
  }

  loadSponsoredPopularCoinsAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init('custom', el.dataset.zoneKey, 'placement:coingeckocom-top-coin', {
        target: `#${el.id}`,
        template: `
              <div data-ads-target="banner">
                <a rel="nofollow noopener" target="_blank" href="##statlink##">
                  <div class="tw-flex tw-mb-3 tw-justify-between tw-gap-x-2">
                    <div class="tw-min-w-0">
                      <div class="tw-flex tw-mb-2 tw-items-center">
                        <img width="24" height="24" alt="##companyTagline##" src="##image##" />
                        <span class="tw-ml-1 tw-text-sm tw-font-medium tw-text-gray-700 dark:tw-text-white dark:tw-text-opacity-60">
                          ##company##
                        </span>
                      </div>

                      <div class="tw-mb-1">
                        <span class="tw-text-xs tw-text-gray-700 dark:tw-text-white dark:tw-text-opacity-87 tw-font-medium">
                          ##companyTagline##
                        </span>
                      </div>

                      <div class="tw-break-words tw-text-xs tw-text-gray-400 dark:tw-text-white dark:tw-text-opacity-60">
                        <div>##description##</div>
                      </div>
                    </div>

                    <div class="tw-text-right">
                      <div class="tw-whitespace-nowrap tw-mb-auto dark:focus:tw-outline-none dark:focus:tw-text-white dark:hover:tw-bg-primary-400 dark:hover:tw-text-white dark:tw-text-white disabled:tw-opacity-50 focus:tw-outline-none focus:tw-text-white hover:tw-bg-primary-700 hover:tw-text-white tw-bg-primary-500 tw-border tw-border-transparent tw-font-medium tw-inline-flex tw-items-center tw-leading-4 tw-rounded-md tw-text-white tw-justify-center tw-px-2.5 tw-py-1.5 tw-text-xs">
                        ##callToAction##
                      </div>

                      <div class="tw-flex tw-mt-2 tw-items-center tw-flex-row-reverse">
                        <div class="tw-text-xs tw-text-gray-400 dark:tw-text-white dark:tw-text-opacity-60 tw-font-medium tw-float-left">Sponsored</div>
                        <img loading="lazy" class="tw-h-5 tw-w-5 tw-mr-1" src="https://static.coingecko.com/s/gecko-65456030ba03df0f83f96e18d0c8449485c1a61dbdeeb733ca69164982489d0e.svg" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              `,
      });
    }
  }

  loadSponsoredSearchAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init('custom', el.dataset.zoneKey, 'placement:coingeckocom-search', {
        target: `#${el.id}`,
        template: `
              <div class="d-flex tw-px-4 tw-py-2.5">
                <a href="##statlink##" target="_blank" rel="noopener" class="d-lg-block" style="line-height: 1">
                  <span style="width: 10vw">
                    <img src="##image##" width="20" height="20" />
                  </span>
                  <span style="width: 90vw; font-size: 12px">
                    ##description##
                  <div class="mt-1 text-muted" style="font-size: 10px">
                    Sponsored
                  </div>
                  </span>
                </a>
              </div>
              `,
      });
    }
  }

  loadSponsoredWalletAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init('custom', el.dataset.zoneKey, 'placement:coingeckocom-wallet', {
        target: `#${el.id}`,
        template: `
              <a  target="_blank"
                  rel="nofollow noopener"
                  class="tw-block ellipsis lg:tw-block lg:tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-my-0.5 tw-mr-1 tw-rounded-md tw-text-sm tw-font-medium tw-bg-primary-100 tw-text-primary-800 hover:tw-bg-primary-200 dark:tw-bg-white dark:tw-bg-opacity-10 dark:hover:tw-bg-opacity-20 dark:tw-text-primary-400"
                  href="##statlink##"
              >
                <i class="far mr-1 fa-ad"></i>##companyTagline##
              </a>
              `,
      });
    }
  }

  // Use CVADC53U for demo/test
  loadSponsoredBoxAds() {
    const sponsoredBoxKey = this._isProduction() ? "CK7DPK7J" : "CVADC53U";

    if (typeof _bsa !== "undefined" && _bsa) {
      const sponsoredBoxTarget = document.getElementById("sponsored_box");

      if (sponsoredBoxTarget) {
        sponsoredBoxTarget.innerHTML = null;

        // format, zoneKey, segment:value, options
        _bsa.init("default", sponsoredBoxKey, "placement:sponsored_box", {
          target: "#sponsored_box",
          align: "horizontal",
        });
      }
    }
  }

  loadPopOverAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init("custom", el.dataset.zoneKey, "placement:coingecko-button", {
        target: `#${el.id}`,
        template: `
              <div>
                <div>
                  <a class="tw-flex tw-py-3" target="_blank" rel="noopener nofollow" href="##statlink##">
                    <img alt="##companyTagline##" src="##small_banner##" style="width: 90px; height: 90px; border-radius: 8px;"/>
                    <div class="tw-pl-4">
                      <img class="tw-mr-0" alt="##companyTagline##" src="##image##" style="width: 20px; height: 20px; display: inline-block"/>
                      <span class="tw-font-medium dark:tw-text-white dark:tw-text-opacity-60 tw-text-sm"> ##company## </span>
                      <div class="tw-mt-2 tw-text-xs tw-text-gray-700 dark:tw-text-white dark:tw-text-opacity-87 tw-font-medium"> ##companyTagline## </div>
                      <div class="tw-mt-1 tw-text-xs tw-text-gray-400 dark:tw-text-white dark:tw-text-opacity-60 tw-font-normal">
                        ##description##
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            `,
      });
    }
  }

  loadSponsoredExchangeAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init("custom", el.dataset.zoneKey, "placement:coingecko-exchange-02", {
        target: `#${el.id}`, //bitflyer exchange ads
        template: `
              <span>
                <span>
                  <img width="20" height="20" class="mr-1" src="##logo##">
                  <a rel="nofollow" href="##statlink##">##company##</a>
                </span>
                <span class="badge badge-warning" title="##description##">
                  Sponsored
                </span>
              </span>
            `,
      });
    }
  }

  loadCoinRowAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.initBatch("custom", el.dataset.zoneKey, `placement:coingecko-${el.dataset.apiSymbol}-token`, {
        target: `#token-${el.dataset.apiSymbol}-js`,
        template: `
          <details class="token-wrap" data-controller="row-ads" data-target="row-ads.rowCardBody">
            <summary class="token-buy">Buy</summary>

            <div class="token-card" data-action="row-ads:click:outside->row-ads#clickOutside">
              <div class="token-sponsor"><img height=20 width=20 src="##image##">##companyTagline##</div>
              <a class="token-img" href="##statlink##" rel="sponsored noopener" target="_blank"><img src="##banner##" loading="lazy"></a>
              <div class="tw-text-xs tw-text-gray-500 dark:tw-text-white dark:tw-text-opacity-60 tw-text-left">##description##</div>
              <a class="token-button" href="##statlink##" rel="sponsored noopener" target="_blank">##callToAction##</a>
              <div class="token-via">Sponsored</div>
            </div>
          </details>
          `,
      });
    }
  }

  loadNftRowAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.initBatch("custom", el.dataset.zoneKey, `placement:coingecko-${el.dataset.apiSymbol}-token`, {
        target: `#${el.id}`,
        template: `
          <details class="token-wrap" data-controller="row-ads" data-target="row-ads.rowCardBody">
            <summary class="token-buy">Buy</summary>

            <div class="token-card tw-w-max" data-action="row-ads:click:outside->row-ads#clickOutside">
              <div class="token-sponsor"><img height=20 width=20 src="##image##">##companyTagline##</div>
              <a class="token-img" href="##statlink##" rel="sponsored noopener" target="_blank"><img src="##banner##"></a>
              <div class="tw-text-xs tw-text-gray-500 dark:tw-text-white dark:tw-text-opacity-60">##description##</div>
              <a class="token-button" href="##statlink##" rel="sponsored noopener" target="_blank">##callToAction##</a>
              <div class="token-via">Sponsored</div>
            </div>
          </details>
          `,
      });
    }
  }

  loadNftPopOverAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init("custom", el.dataset.zoneKey, `placement:coingecko-button`, {
        target: `#${el.id}`,
        template: `
              <div>
                <div>
                  <a class="tw-flex tw-py-3" target="_blank" rel="noopener nofollow" href="##statlink##">
                    <img alt="##companyTagline##" src="##small_banner##" style="width: 90px; height: 90px; border-radius: 8px;"/>
                    <div class="tw-pl-4">
                        <img class="tw-mr-0" alt="##companyTagline##" src="##image##" style="width: 20px; height: 20px; display: inline-block"/>
                        <span class="tw-font-medium dark:tw-text-white dark:tw-text-opacity-60 tw-text-sm"> ##companyTagline## </span>
                        <div class="tw-mt-2 tw-text-xs tw-text-gray-400 dark:tw-text-white dark:tw-text-opacity-60 tw-font-normal">
                          ##description##
                        </div>
                    </div>
                  </a>
                </div>
              </div>
          `,
      });
    }
  }

  loadSelectedArticleAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init("custom", "CWYDE23W", "placement:coingeckocom-selected-articles", {
        target: `#${el.id}`, id: "selectedArticles",
        template: `
      <a href="##statlink##" rel="sponsored noopener" target="_blank" title="##companyTagline##" class="!tw-p-6 !tw-rounded-xl !tw-border-gray-200 !tw-gap-8 tw-shadow-sm dark:!tw-bg-white/5 dark:!tw-border-opacity-12">
        <img class="selectedLogo" src="##logo##">
        <div class="selectedMain">
          <div class="selectedText">
            <div class="selectedTitle !tw-font-bold !tw-text-gray-900 dark:!tw-text-white dark:!tw-text-opacity-87 md:!tw-text-xl !tw-text-lg !tw-mb-1">##companyTagline##</div>
            <div class="selectedDescription !tw-font-normal !tw-text-gray-500 dark:!tw-text-white dark:!tw-text-opacity-60 !tw-text-sm">##description##</div>
          </div>
          <div class="selectedCta">
            <div class="selectedButton tw-bg-primary-500 !tw-rounded-md !tw-text-sm">##callToAction##</div>
            <div class="selectedSponsored !tw-text-xs !tw-text-gray-400 dark:!tw-text-white/60 !tw-float-left"><img src="https://static.coingecko.com/s/gecko-65456030ba03df0f83f96e18d0c8449485c1a61dbdeeb733ca69164982489d0e.svg"><strong>Sponsored</strong></div>
          </div>
        </div>
      </a>
      `
      });
    }
  }

  _bsaHideOtherTokens(target) {
    let parentEl = target.parentElement;
    if (parentEl.open === true) {
      parentEl.open === false;
    } else {
      document.querySelectorAll(".token-wrap").forEach((node, index) => {
        if (node.open === true) {
          node.open = false;
        }
      });
    }
  }

  _bsaInitTokens() {
    document.querySelectorAll(".token-buy").forEach((item) => {
      item.addEventListener("click", () => this._bsaHideOtherTokens(item));
    });
  }

  _isProduction() {
    return process.env.NODE_ENV === "production";
  }
}
