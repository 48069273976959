export default () => {
  Alpine.data("progressIndicator", (stepsCount, startingStep, cycleSteps) => ({
    currentStep: startingStep,

    step(e) {
      if (e.detail.next) {
        this.nextStep();
      } else {
        this.previousStep();
      }
    },

    nextStep() {
      if (this.currentStep < (stepsCount - 1)) {
        this.currentStep++
      } else if (cycleSteps) {
        this.currentStep = 0
      }
    },

    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      } else if (cycleSteps) {
        this.currentStep = stepsCount - 1;
      }
    },
  }));
}
