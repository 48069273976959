import { Controller } from "stimulus";
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['tooltip', 'anchor']

  static values = {
    anchorId: String,
    tooltipPlacement: String
  }

  getTooltipConfig(position) {
    return {
      placement: position,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 2],
          },
        },
      ],
    }
  }

  connect() {
    this.popperInstance = createPopper(
      this.anchorTarget, this.tooltipTarget,
      this.getTooltipConfig(this.tooltipPlacementValue)
    );
  }

  show(event) {
    this.tooltipTarget.classList.remove("!tw-hidden");
    this.popperInstance.update();
  }

  hide(event) {
    this.tooltipTarget.classList.add("!tw-hidden");
  }
}
