import { Controller } from "stimulus";
import { copyToClipboard } from "../../util/copy_to_clipboard";

export default class extends Controller {
  static targets = ["address", "buttonIcon"];

  copyDonationAddress(e) {
    const textTarget = e.currentTarget.closest("div[x-ref='container']").querySelector("input");
    const iconTarget = e.currentTarget.querySelector("i");

    copyToClipboard(textTarget, iconTarget);
  }

  toggleFooterGroup(e) {
    const footerGroup = e.currentTarget;
    const footerGroupLinks = document.getElementById(`${footerGroup.id}-group`);
    const toggle = footerGroup.querySelector("i");

    toggle.classList.toggle("fa-plus");
    toggle.classList.toggle("fa-minus");
    footerGroup.classList.toggle("tw-border-b")
    footerGroupLinks.classList.toggle("tw-border-b");
    footerGroupLinks.classList.toggle("tw-pb-4");
    footerGroupLinks.classList.toggle("tw-h-0");
  }
}
