import {Controller} from "stimulus";
import {createPopper} from '@popperjs/core';
import {useClickOutside} from "stimulus-use";
import {convertAlpineTransitions} from "../../util";
import {enter, leave} from "el-transition";

export default class extends Controller {
  handleOver(e) {
    if (this._isMobile()) return;

    const itemTarget = e.currentTarget.querySelector(".nav-items");
    const labelTarget = e.currentTarget.querySelector(".nav-label");
    labelTarget.classList.add("2lg:!tw-text-primary-500", "2lg:dark:!tw-text-primary-500");
    itemTarget.classList.add("2lg:tw-block");
    itemTarget.classList.remove("2lg:tw-hidden");
  }

  handleAway(e) {
    if (this._isMobile()) return;

    const itemTarget = e.currentTarget.querySelector(".nav-items");
    const labelTarget = e.currentTarget.querySelector(".nav-label");
    labelTarget.classList.remove("2lg:!tw-text-primary-500", "2lg:dark:!tw-text-primary-500");
    itemTarget.classList.add("2lg:tw-hidden");
    itemTarget.classList.remove("2lg:tw-block");
    itemTarget.classList.remove("open");
  }

  handleClick(e) {
    if (!this._isMobile()) return;

    const targetTag = event.target.tagName.toLowerCase();
    if (targetTag === "a" || targetTag === "span") return;

    const itemTarget = e.currentTarget.querySelector(".nav-items");
    const toggleTarget  = e.currentTarget.querySelector(".nav-items-toggle");

    itemTarget.classList.toggle("open");
    itemTarget.style.maxHeight = itemTarget.classList.contains("open") ? itemTarget.scrollHeight + "px" : null;

    leave(toggleTarget, "fade").then(function() {
      toggleTarget.classList.toggle("fa-plus");
      toggleTarget.classList.toggle("fa-minus");
    });
  }

  toggleMenu(e) {
    if (!this._isMobile()) return;

    const menuToggleTarget = e.currentTarget;
    const wrapperTarget = document.body.querySelector(".nav-wrapper");
    const backdropTarget = document.body.querySelector(".nav-backdrop");

    wrapperTarget.classList.toggle("tw-hidden");
    backdropTarget.classList.toggle("tw-hidden");
    menuToggleTarget.classList.toggle("fa-bars");
    menuToggleTarget.classList.toggle("fa-xmark-large");
    document.body.classList.toggle("navbar-popup-open");
  }

  _isMobile() {
    return window.innerWidth < 1200;
  }
}
