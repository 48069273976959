import { Controller } from "stimulus";
import { USER_UUID_KEY } from "../../../config";

export default class extends Controller {

  connect() {
    // generate uuid for non-login user as kevel userDB identifier
    let uuid = localStorage.getItem(USER_UUID_KEY);

    if (uuid === null) {
      localStorage.setItem(USER_UUID_KEY, self.crypto.randomUUID());
    }
  }
}
