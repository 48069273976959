import { Controller } from "stimulus";
import { EventToast } from "../events";
import { simpleNotificationToast } from "../template/toast_notification";
import {
  debounce
} from "../util";
import {
  addPortfolioNftContractRequest,
  searchPortfolioNftContractRequest
} from "../routes/nft_portfolio";

export default class extends Controller {
  static targets = ["nftContractSearchInput", "nftContractResultsList", "nftContract"];

  initialize() {
    this.debouncedSearchNftContract = debounce(this.searchNftContract.bind(this), 300);

    this.nftContractResultsListTarget.innerHTML = this.trendingNftContractTemplate;
  }

  get addNftContractModal() {
    return $(`#addNftContractModal_${this.nftPortfolioId}`);
  }

  get nftPortfolioId() {
    return this.data.get("nftPortfolioId");
  }

  get nftPortfolioTitle() {
    return this.data.get("nftPortfolioTitle");
  }

  get nftContractAdded() {
    return JSON.parse(this.data.get("nftContractAdded"));
  }

  searchNftContract() {
    const searchPath = this.nftContractSearchInputTarget.dataset.actionPath;
    const nftContractSearchInput = this.nftContractSearchInputTarget.value.trim();

    if (nftContractSearchInput === "") {
      // Show trending NFTs if search input blank
      this.nftContractResultsListTarget.innerHTML = this.trendingNftContractTemplate;
    } else {
      this.nftContractResultsListTarget.innerHTML = null;

      // Keep track latest search query
      this._searchCount = this._searchCount || 0;
      this._searchCount += 1;
      const searchCount = this._searchCount;

      searchPortfolioNftContractRequest(
        {
          searchPath: searchPath,
          nftContractSearchInput: nftContractSearchInput
        }, (res) => {
          if (searchCount != this._searchCount) {
            // Ignore stale query results
            return;
          }

          if (res) {
            this.nftContractResultsListTarget.innerHTML = res;
          }
        });
    }
  }

  get trendingNftContractTemplate() {
    const template = this.trendingNftContracts.map((nftContract) => {
      const imgSrc = `<img src="${nftContract.thumb}" alt="${nftContract.id}" height="18px" width="18px" class="mr-2" />`;
      const label = `${this._truncateNftContractName(nftContract.name)} (${nftContract.symbol.toUpperCase()})`;
      const content = imgSrc + label;

      if (this.nftContractAdded.includes(nftContract.nft_contract_id)) {
        return `
          <li class="list-group-item tw-flex tw-items-center">
            ${content}
            <span class="tw-inline-flex tw-items-center tw-font-medium tw-bg-primary-100 tw-text-primary-800 
            hover:tw-bg-primary-200 hover:tw-text-primary-900 dark:tw-bg-white dark:tw-bg-opacity-12 
            hover:dark:tw-bg-opacity-24 dark:tw-text-primary-400 tw-px-2 tw-py-0.5 tw-rounded tw-text-xs tw-max-h-5 tw-ml-2">
              ${I18n.t("search.added")}
            </span>
          </li>
        `;
      } else {
        return `
          <li class="list-group-item tw-flex tw-items-center"
            data-action="click->nft-portfolios-search-contract#addNftContract"
            data-dismiss="modal"
            data-refresh="true"
            data-source="trending_nft"
            data-nft-portfolio-id="${this.nftPortfolioId}"
            data-nft-contract-id="${nftContract.nft_contract_id}"
            data-nft-contract-symbol="${nftContract.symbol.toUpperCase()}">
            ${content}
          </li>
        `;
      }
    }).join("");

    return `
      <div class="tw-p-2 tw-mb-2 tw-font-bold">
          ${I18n.t("search.trending_nft")}
      </div>
      <ul class="list-group tw-max-h-70vh tw-overflow-auto">
        ${template}
      </ul>
    `;
  }

  // reference: nft_portfolios/_add_nft_contract_modal.html.erb
  get trendingNftContracts() {
    return (this.nftContractSearchInputTarget.dataset.trending ? JSON.parse(this.nftContractSearchInputTarget.dataset.trending) : []);
  }

  addNftContract(e) {
    const nftContractId = e.currentTarget.getAttribute("data-nft-contract-id");
    const source = e.currentTarget.getAttribute("data-source");
    const nftPortfolioId = e.currentTarget.getAttribute("data-nft-portfolio-id");
    const refresh = e.currentTarget.getAttribute("data-refresh");
    const nftContractSymbol = e.currentTarget.getAttribute("data-nft-contract-symbol");
    const nftPortfolioTitle = this.nftPortfolioTitle;

    const params = {
      nftPortfolioId: nftPortfolioId,
      nftContractId: nftContractId,
      source: source
    };



    addPortfolioNftContractRequest(params, (res) => {
      if (res.status >= 400) {
        return this._showUnsuccessfulToast(3000) ;
      }

      this._showNotificationToast(nftContractSymbol, nftPortfolioTitle);

      if (refresh.toString() === "true") {
        window.location.reload();
      }
    });

    e.preventDefault();
  }

  _truncateNftContractName(name) {
    return name.length > 30 ? `${name.substring(0, 30)}...` : name;
  }

  _showNotificationToast(nftContractSymbol, nftPortfolioTitle) {
    const toastSuccessMessage = I18n.t("nft_portfolio.favorite", { symbol: nftContractSymbol }) + ` - <b>${nftPortfolioTitle}</b>`;

    window.dispatchEvent(
      new CustomEvent(EventToast, {
        detail: {
          notice: simpleNotificationToast(
            toastSuccessMessage
          )
        }
      })
    );
  }

  _showUnsuccessfulToast(timeout) {
    window.dispatchEvent(
      new CustomEvent(EventToast, {
        detail: {
          notice: simpleNotificationToast(I18n.t("toast.unsuccessful"), timeout)
        }
      })
    )
  }

}