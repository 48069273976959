import { Controller } from "stimulus";
import { EventCheckUserLogin } from "../../events";
import {
  getPortfolioNftContracts,
  addPortfolioNftContractRequest,
  removePortfolioNftContractRequest
} from "../../routes/nft_portfolio";
import Cookies from "js-cookie";
import { trackEvent } from "../../analytics";
import { SELECT_ADD_PORTFOLIO_NFT_CTA } from "../../mixpanel_config";

export default class extends Controller {

  static targets = ["dropdown", "dropdownContent", "dropdownItem"];

  connect() {
    Cookies.remove("portfolioNftContractId");

    window.addEventListener(EventCheckUserLogin, e => {
      this.loggedIn = e.detail.userLoggedIn;

      if (this.loggedIn) {
        getPortfolioNftContracts((res) => {
          this.loggedIn = true;
          return res.status === 200
        }, (res) => {
          if (!res) {
            return;
          }
          this.nftPortfolios = res;
          this.defaultNftPortfolio = this.nftPortfolios[0];
          this._updateAllStars();
        });
      }
    });
  }

  handle(e) {
    const star = e.currentTarget;
    let nftId = star.getAttribute("data-nft-contract-id");
    let nftSlug = star.getAttribute("data-nft-contract-slug");
    let origin = star.getAttribute("data-analytics-origin");

    if (!this.loggedIn) {
      Cookies.set("portfolioNftContractId", nftId);
      return Modal.show("auth_modal");
    }

    if (!nftId || !this.defaultNftPortfolio) {
      return;
    }

    const nftContractSymbol = star.getAttribute("data-nft-contract-symbol");

    nftId = +nftId;
    const defaultPortfolioId = +this.defaultNftPortfolio.id;

    const addedToNftPortfolio = this.defaultNftPortfolio.nft_contract_ids.includes(nftId);
    if (addedToNftPortfolio) {
      this._removeNft(nftId, defaultPortfolioId, nftContractSymbol);
    } else {
      this._addNft(nftId, defaultPortfolioId, this.defaultNftPortfolio.title, nftContractSymbol);
      trackEvent(SELECT_ADD_PORTFOLIO_NFT_CTA, { origin, nft_name: nftSlug })
    }
  }

  _removeNft(nftContractId, nftPortfolioId, nftContractSymbol) {
    Modal.confirm(
      I18n.t("nft.remove_nft_contract"),
      I18n.t("nft.remove_nft_contract_subject"),
      I18n.t("labels.confirm"),
      I18n.t("labels.cancel")
    ).then((result) => {
      if (!result.confirm) return;

      removePortfolioNftContractRequest(
        { nftContractId, nftPortfolioId },
        (res) => {
          // Update local data to remove nft ID.
          let nftIdIndex = this.defaultNftPortfolio.nft_contract_ids.indexOf(nftContractId);
          if (nftIdIndex !== -1) {
            this.defaultNftPortfolio.nft_contract_ids.splice(nftIdIndex, 1);
          }

          Toaster.toast(I18n.t("portfolio.unfavorite", { symbol: nftContractSymbol, portfolioTitle: res.nft_portfolio_title }));
          this._updateAllStars();

          // Reload page to refresh the watchlist table.
          if (window.location.href.includes("/nft-portfolio")) {
            window.location.reload();
          }
        });
    });
  }

  _addNft(nftContractId, nftPortfolioId, nftPortfolioTitle, nftContractSymbol) {
    addPortfolioNftContractRequest(
      { nftContractId, nftPortfolioId },
      (res) => {
        this.defaultNftPortfolio.nft_contract_ids.push(res.nft_contract_id);

        this._showNotificationToast(nftContractSymbol, nftPortfolioTitle);
        this._updateAllStars();
      });
  }

  _showNotificationToast(nftContractSymbol, nftPortfolioTitle) {
    const nftPortfolioPath = `/${document.body.dataset.locale}/nft-portfolio`;
    const hyperlinkText = I18n.t("portfolio.view", { portfolioTitle: nftPortfolioTitle });
    const successMessage = I18n.t("nft.toast_success_message", { symbol: nftContractSymbol, nftPortfolioTitle: nftPortfolioTitle });

    Toaster.toast(successMessage, {
      action: {
        message: hyperlinkText,
        options: {
          href: nftPortfolioPath,
        }
      }
    })
  }

  _updateAllStars() {
    const nftIdStar = {}
    this.defaultNftPortfolio.nft_contract_ids.forEach(id => nftIdStar[id] = true);

    this.element.querySelectorAll(`i.fa-star`).forEach(star => {
      const nftId = +star.getAttribute("data-nft-contract-id");
      if (!nftId) return;

      const enabled = !!nftIdStar[nftId];

      star.classList.toggle("far", !enabled);
      star.classList.toggle("fas", enabled);
      star.classList.toggle("tw-text-yellow-500", enabled);
    });
  }
}
