import { loadFlipDown } from "../util/load_package";

export default() => {
  Alpine.data("countdown", () => ({
    async init() {
      const FlipDown = await loadFlipDown();

      const countdown_component = this.$el
      const timestamp = countdown_component.dataset.timestamp
      new FlipDown(
        Number(timestamp),
        countdown_component.id,
        {
          theme: "gecko",
          headings: [
            I18n.t("datetime_units.days"),
            I18n.t("datetime_units.hours"),
            I18n.t("datetime_units.minutes"),
            I18n.t("datetime_units.seconds"),
          ],
        }
      ).start()
    },
  }));
}