export default () => {
  Alpine.data("input", () => ({
    showHideInputError(e) {
      showHideInputError(e, this.$refs.container, this.$refs.error);
    }
  }));
}

export function showHideInputError(e, containerTarget, errorMessageTarget) {
  e.stopImmediatePropagation();
  if (e.target.id == null || e.detail.show == null) return;

  containerTarget.classList.toggle("gecko-error", e.detail.show);
  errorMessageTarget.classList.toggle("tw-hidden", !e.detail.show);
  errorMessageTarget.classList.toggle("tw-block", e.detail.show);

  errorMessageTarget.textContent = e.detail.show ? e.detail.errorMessage : "";
}
