import { Controller } from "stimulus";
import { loadScript } from "../../util/load_script";

export default class extends Controller {
  static targets = ["captchaForm", "submit", "resetPassword", "requestForm", "rewardsPage", "rewardContractAddressPage"]

  connect(){
    if (window.location.pathname.includes("request-form") || window.location.pathname.includes("rewards")) {
      this.loadCaptcha()
    }
  }

  loadCaptcha(e) {
    if (!window.captchaLoaded) {
      window.captchaLoaded = true

      loadScript(true)
        .url("https://hcaptcha.com/1/api.js")
        .then(() => this._renderCaptcha());
    } else {
      this._renderCaptcha();
    }
  }

  _renderCaptcha() {
    // Flag is set, but not loaded fully; try again in 50ms.
    if (window.captchaLoaded && !window.hcaptcha) {
      return setTimeout(() => this._renderCaptcha(), 50);
    }

    if (this.captchaFormTarget.innerHTML.trim() != "") {
      return;
    }

    this.captchaFormTarget.classList.remove("tw-hidden");
    const siteKey = this.captchaFormTarget.dataset.siteKey;

    window.hcaptcha.render(
      this.captchaFormTarget.id,
      { "sitekey": siteKey, "callback": this._enableFormSubmitButton.bind(this) }
    );
  }

  _setResponseToken(responseToken) {
    const input = document.createElement("input");

    input.setAttribute("type", "hidden");
    input.setAttribute("name", "response_token");
    input.setAttribute("value", responseToken);

    this.captchaFormTarget.appendChild(input);
  }

  _enableFormSubmitButton(responseToken) {
    this._setResponseToken(responseToken);
    window.captchaVerified = true;
    if (window.passwordVerified === true) {
      this.submitTarget.disabled = false;
    } else if (this.hasRequestFormTarget) {
      this.submitTarget.disabled = false;
    } else if (this.hasRewardsPageTarget) {
      this.submitTarget.disabled = false;
    } else if (this.hasRewardContractAddressPageTarget) {
      this.submitTarget.disabled = false;
    } else if (this.hasResetPasswordTarget) {
      this.submitTarget.disabled = false;
    }
  }
}
