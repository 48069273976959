import "stylesheets_v2/application";
import "../../gecko-sort";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { initializeAlpine } from "../alpine";

const application = Application.start();
const context = require.context("controllers_v2/global", false, /.js$/);
const adsContext = require.context("controllers_v2/shared/ads", false, /.js$/);
const sharedContext = require.context("controllers_v2/shared", false, /.js$/);
const searchContext = require.context("controllers", false, /search_.*controller.js$/);

initializeAlpine();
application.load(definitionsFromContext(context));
application.load(definitionsFromContext(adsContext));
application.load(definitionsFromContext(sharedContext));
application.load(definitionsFromContext(searchContext));
