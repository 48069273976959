import { Controller } from "stimulus";
import { buildChartConfig } from "../../template/charts/pie_chart";
import { EventGeckoChartRendered } from "../../events";
import { loadHighcharts } from "../../util/load_package";

let Highcharts;
export default class extends Controller {
  // Provided values, from data attributes.
  dataUrl = null;
  dataJson = null;
  isPercentage = false;

  // Inferred values, not from data attributes.
  chartInstance = null;

  async connect() {
    Highcharts = await loadHighcharts();

    // Only render chart when it's visible.
    if (this.element.offsetParent !== null) {
      this.rerenderChart();
    }

    // Prevents rerender value from being set before controller listens to it.
    this.element.dataset.pieChartRerenderValue = false;
  }


  async getData() {
    let data = null;

    if (this.dataUrl) {
      data = await fetch(this.dataUrl, { credentials: "same-origin" }).then(res => res.json());
    }

    if (this.dataJson && !data) {
      data = JSON.parse(this.dataJson);
    }

    if (!data) {
      return null;
    }


    // More than 8 items, show first 7 and consolidate the rest under "Others".
    if (data && data.length > 8) {
      const sortedData = data.sort((a, b) => parseFloat(b.y) - parseFloat(a.y));
      const displayData = [...sortedData.slice(0, 7)];

      // Add up values of 8th item onwards to consolidate.
      const remainingTotal = sortedData.slice(8).reduce((s, a) => s + a.y, 0);
      displayData.push({
        name: I18n.t("charts.others"),
        y: remainingTotal,
        clickExpand: true,
      });

      return displayData;
    }

    return data;
  }


  renderChart() {
    this.chartInstance?.showLoading("<i class='far fa-fw fa-spinner-third fa-spin tw-text-2xl'></i>");

    this.getData().then((data) => {
      this.chartInstance = Highcharts.chart(this.element.id, buildChartConfig(this.element, data, this.isPercentage));

      // Inform other controllers that the chart was rendered recently.
      this.element.dispatchEvent(new CustomEvent(EventGeckoChartRendered, {
        bubbles: true,
        detail: { type: "pie", chart: this.chartInstance }
      }));
    });
  }

  rerenderChart() {
    this.dataUrl = this.element.dataset.dataUrl;
    this.dataJson = this.element.dataset.dataJson;
    this.isPercentage = this.element.dataset.isPercentage === "true";

    if (!this.dataUrl && !this.dataJson) {
      return;
    }

    this.renderChart();
  }


  // Watch for re-renders with the "data-pie-chart-rerender-value" attribute.
  // Enables triggering a re-render from other controllers by changing the attribute value.
  // https://stimulus.hotwired.dev/reference/values#change-callbacks
  static values = { rerender: Boolean };
  rerenderValueChanged() {
    if (!Highcharts || this.rerenderValue === false) {
      return;
    }

    this.rerenderValue = false;
    this.rerenderChart();
  }
}
