import {Controller} from "stimulus";
import {EventCurrencyChanged} from "../../events";
import {setActiveCurrency, getActiveCurrency} from "../../util/currency";

export default class extends Controller {
  static targets = ["searchInput", "itemGroup", "noSearchResults", "selectedIcon"];

  handleSearchInput() {
    let query = this.searchInputTarget.value.toLowerCase();
    let totalGroups = 0;
    let hiddenGroups = 0;

    this.itemGroupTargets.forEach(itemGroup => {
      Array.from(itemGroup.children).forEach(settingItem => {
        settingItem.classList.toggle("tw-hidden", settingItem.innerText.trim().toLowerCase().indexOf(query) < 0)
      });

      let hasResults = itemGroup.querySelectorAll(".setting-item:not(.tw-hidden)").length > 0
      itemGroup.classList.toggle("tw-hidden", !hasResults);
      itemGroup.previousElementSibling.classList.toggle("tw-hidden", !hasResults);

      totalGroups++;
      if (!hasResults) hiddenGroups++;
    });

    this.noSearchResultsTarget.innerText = hiddenGroups >= totalGroups ? this.noSearchResultsTarget.dataset.text : "";
  }
}
